import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { NavLink } from "react-router-dom";
import styles from "./styles/sideDrawer.js";

const drawerWidth = 240;

export default function PermanentDrawerLeft({ children }) {
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}>
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            Dwellful Developer Portal
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <List>
          <ListItem button sx={{ pl: 4 }}>
            <NavLink activeStyle={styles.disableClick} to={"/"}>
              <ListItemText>Home</ListItemText>
            </NavLink>
          </ListItem>
        </List>

        <Divider />
        <Accordion sx={{ p: 0 }}>
          <AccordionSummary aria-label="Expand" aria-controls="-content" id="-header" expandIcon={<ExpandMoreIcon />}>
            <ListItem>
              <ListItemText>API</ListItemText>
            </ListItem>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              {[{ title: "SmartMatch", link: "smartMatch" }].map((item, index) => (
                <NavLink activeStyle={styles.disableClick} to={`/api/${item.link}`}>
                  <ListItem button key={item.title}>
                    <ListItemText primary={item.title} />
                  </ListItem>
                </NavLink>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>

        <Divider />
        <Accordion>
          <AccordionSummary aria-label="Expand" aria-controls="-content" id="-header" expandIcon={<ExpandMoreIcon />}>
            <ListItem>
              <ListItemText>Fastpath Widget</ListItemText>
            </ListItem>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              {[
                { title: "Configuration", link: "config" },
                { title: "Installation", link: "install" },
              ].map((item, index) => (
                <NavLink activeStyle={styles.disableClick} to={`/fastpath/${item.link}`}>
                  <ListItem button key={item.title}>
                    <ListItemText primary={item.title} />
                  </ListItem>
                </NavLink>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>

        <Divider />
      </Drawer>

      <Box component="main" sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}>
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}
