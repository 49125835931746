import { React, useState } from "react";
import reactCSS from "reactcss";
import { PhotoshopPicker } from "react-color";
import { useDebouncedCallback } from "use-debounce";
function ColorPickerCustom({ value, onChange }) {
  // displayColorPicker: false,
  //   currentColor: {
  //     r: "241",
  //     g: "112",
  //     b: "19",
  //     a: "1",
  //   },
  // newColor: {
  //   r: "241",
  //   g: "112",
  //   b: "19",
  //   a: "1",
  // },

  const debouncedOnChange = useDebouncedCallback(
    // function
    (color) => {
      onChange(color);
    },
    // delay in ms
    100
  );

  function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }
  function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  }

  function rgbToHex({ r, g, b }) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
  }
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [currentColor, setCurrentColor] = useState({
    ...hexToRgb(value || "#0033ff"),
    a: "1",
  });
  const [newColor, setNewColor] = useState({
    ...hexToRgb(value || "#0033ff"),
    a: "1",
  });

  // const onChangeDebounce = () =>
  //   setInterval(() => {
  //     debugger;
  //     if (rgbToHex(newColor) != rgbToHex(currentColor)) {
  //       onChange(rgbToHex(newColor));
  //     }
  //   }, 200);
  // onChangeDebounce();
  const handleClick = () => {
    setDisplayColorPicker((currentVal) => !currentVal);
  };

  const handleClose = (e) => {
    debugger;
    if (e.target === e.currentTarget) setDisplayColorPicker(false);
    debouncedOnChange(rgbToHex(currentColor));
    setNewColor(currentColor);
  };

  const handleAccept = () => {
    setCurrentColor(newColor);
    debouncedOnChange(rgbToHex(newColor));
    setDisplayColorPicker(false);
  };

  const handleChange = (color, event) => {
    setNewColor(color.rgb);
    debouncedOnChange(rgbToHex(newColor));
  };

  const handleCancel = () => {
    setNewColor(currentColor);
    debouncedOnChange(rgbToHex(currentColor));
    //setDisplayColorPicker(false);
  };

  const styles = reactCSS({
    default: {
      color: {
        width: "36px",
        height: "14px",
        borderRadius: "2px",
        background: `rgba(${currentColor.r}, ${currentColor.g}, ${currentColor.b}, ${currentColor.a})`,
      },
      swatch: {
        padding: "5px",
        background: "#fff",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  return (
    <div>
      <div style={styles.swatch} onClick={handleClick}>
        <div style={styles.color} />
      </div>
      {displayColorPicker ? (
        <div style={styles.popover}>
          {/* <div style={{ ...styles.cover, backgroundColor: "rgba(0,0,0,0.35)" }} onClick={this.handleClose}> */}
          <PhotoshopPicker color={newColor} onChange={handleChange} onAccept={handleAccept} onCancel={handleCancel} />
          <div style={{ position: "absolute", right: "5px", top: "-3px" }} onClick={handleClose}>
            x
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default ColorPickerCustom;
