const functionDefinition =
  'let i = d.getElementById(l.target).appendChild(Object.assign(d.createElement("iframe"),{src:`${e}?${new u({iid:l.iid,eid:l.eid,wt:l.target,...l.data}).toString()}`,style:"width: 100%; border:none"}));';
const responsiveListener = "if(x.data && x.data.type==ll && x.data.action==f[0]){i.style.height=x.data.height}";

function generateScript({ iid, target, eid, isResponsive, widgetInitializedAction, widgetInteractedAction, widgetSubmittedAction, widgetRejectedAction, data, triggerMode }) {
  const actionNames = ["widgetInitializedAction", "widgetInteractedAction", "widgetSubmittedAction", "widgetRejectedAction"];

  const widgetInitializedActionCode =
    widgetInitializedAction && `if(x.data && x.data.type==ll && x.data.action==f[1] && typeof(widgetInitializedAction)=='function'){widgetInitializedAction(x)}`;
  const widgetInteractedActionCode =
    widgetInteractedAction && `if(x.data && x.data.type==ll && x.data.action==f[2] && typeof(widgetInteractedAction)=='function'){widgetInteractedAction(x)}`;
  const widgetSubmittedActionCode =
    widgetSubmittedAction && `if(x.data && x.data.type==ll && x.data.action==f[3] && typeof(widgetSubmittedAction)=='function'){widgetSubmittedAction(x)}`;
  const widgetRejectedActionCode =
    widgetRejectedAction && `if(x.data && x.data.type==ll && x.data.action==f[4]  && typeof(widgetRejectedAction)=='function'){widgetRejectedAction(x)}`;

  const listenersArrary = [
    widgetInitializedActionCode,
    widgetInteractedActionCode,
    widgetSubmittedActionCode,
    widgetRejectedActionCode,
    isResponsive ? responsiveListener : null,
  ].filter((item) => item);
  const eventListener = listenersArrary.length ? `w.addEventListener("message",(x)=>{${listenersArrary.join(";")}})` : "";

  let sampleListenerSnippets = [widgetInitializedAction, widgetInteractedAction, widgetSubmittedAction, widgetRejectedAction]
    .filter((item) => item)
    .map(
      (action, index) =>
        `\n\nfunction ${actionNames[index]}(x)
  {

    */
    Your Code Snippet Here
    */

  }`
    );

  const iife = `!function(d,w,e,ll,f,u,l){${functionDefinition} ${eventListener}}
    (document,window,'https://widget-dev.dwellful.com',"dwellfulCallback",["onContentHeightChanged","widget-initialized","widget-first-interaction","widget-submitted","widget-rejected"],URLSearchParams,{
	iid: '${iid}',
	target: '${target}',
	eid:'${eid}',
	${
    data.isSelected
      ? `data: {
		 ${data.firstName ? `firstName:"${data.firstName}",` : ""}\
			${data.lastName ? `\n \t \t lastName:"${data.lastName}",` : ""}\
			${data.phone ? `\n \t \t phone:"${data.phone}",` : ""}\
			${data.email ? `\n \t \t email:"${data.email}",` : ""}\
			${data.type != "none" ? `\n \t \t type:"${data.type}",` : ""}\
			${data.propType != "none" ? `\n \t \t propType:"${data.propType}",` : ""}\
			${data.location ? `\n \t \t location:"${data.location}",` : ""}\
			${data.budget ? `\n \t \t budget:"${data.budget}",` : ""}\
			${data.agent != "none" ? `\n \t \t agent:"${data.agent}",` : ""}\
			${data.approved != "none" ? `\n \t \t approved:"${data.approved}",` : ""}\
		}`
      : ""
  }\n})`;

  if (triggerMode == "load") {
    return `window.addEventListener('load', (event) =>{${iife}})${sampleListenerSnippets.join("")}`;
  }
  return `window.loadDwellfulWidget=()=>{${iife}}${sampleListenerSnippets.join("")}`;
}

export default generateScript;
