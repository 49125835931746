// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import StyleConfigurationContainer from "./fastpath/style-config";
import ScriptConfigurationContainer from "./fastpath/install-config";
import Home from "./home/home";
import SmartMatchDocs from "./api/smartmatch/smartmatch";
import PermanentDrawerLeft from "./components/sideDrawer";
import SampleStep from "./fastpath/style-config/sample-step/sampleStepIframe";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";

function App() {
  if (window.location.pathname == "/fastpath/sampleStep") {
    return <SampleStep></SampleStep>;
  }

  return (
    <BrowserRouter>
      <div className="App" style={{ overflow: "hidden" }}>
        <PermanentDrawerLeft>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="api/smartMatch" element={<SmartMatchDocs />} />
            <Route path="fastpath/config" element={<StyleConfigurationContainer />} />
            <Route path="fastpath/install" element={<ScriptConfigurationContainer />} />
          </Routes>
        </PermanentDrawerLeft>
      </div>
    </BrowserRouter>
  );
}

export default App;
