import generateCustomStyle from "./generateCustomStyle";

function applyStyle(themeConfig) {
  // //
  // ${[...new Set([headerFont.family, subHeaderFont.family, footernoteFont.family, reviewFont.family, textFont.family])]
  //   .map((fontFamily) => `@import url('https://fonts.googleapis.com/css?family=${fontFamily.split(" ").join("+")}&display=swap'); \n`)
  //   .join("")}
  let styleText = generateCustomStyle(themeConfig);
  var style = document.createElement("style");
  style.type = "text/css";
  style.innerHTML = styleText;
  style.id = "customStyle";

  //styleElement && styleElement.replaceWith(style);

  document.getElementById("sampleStepIframe").contentDocument.getElementById("customStyle") &&
    document.getElementById("sampleStepIframe").contentDocument.getElementById("customStyle").replaceWith(style);

  return styleText;
}

export default applyStyle;
