import { Typography, Grid } from "@mui/material";
function Home() {
  return (
    <>
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Typography variant="h3">Dwellful Developer Portal</Typography>
        <Grid sx={{ m: 6 }}>
          <img src="./dwellful_with_icon_dark.png"></img>
        </Grid>
        <Typography variant="h6">
          Dwellful Developer Portal contains guides and documentation for integration of various APIs and tools offered by Dwellful for developers and partners.
        </Typography>
      </Grid>
    </>
  );
}
export default Home;
