import { MenuItem, Select } from "@mui/material";
import ColorPickerCustom from "./colorPickr";
function FontSelection({ title, fontObject, setFontObject, fontRange, children }) {
  const range = (start, end, step = 1) => {
    let output = [];
    if (typeof end === "undefined") {
      end = start;
      start = 0;
    }
    for (let i = start; i < end; i += step) {
      output.push(i);
    }
    return output;
  };

  return (
    <div className="row form-group  m-3">
      <label className="col-md-12 control-label">{title}</label>

      {fontObject.color && (
        <div className="col-md-1  m-3">
          <label className="control-label" for="buttonColor">
            Color
          </label>

          <ColorPickerCustom value={fontObject.color} onChange={(color) => setFontObject((font) => ({ ...font, color: color }))}></ColorPickerCustom>
        </div>
      )}

      {fontObject.size && (
        <div className="col-md-2  m-3">
          <label className="control-label" for="buttonColor">
            Size
          </label>
          <div>
            <Select
              variant="standard"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={fontObject.size}
              label="Size"
              onChange={(e) => setFontObject((font) => ({ ...font, size: e.target.value }))}
            >
              {range(fontRange.start, fontRange.end).map((size) => (
                <MenuItem value={`${size}`}>{size}</MenuItem>
              ))}
            </Select>
          </div>
        </div>
      )}

      {fontObject.weight && (
        <div className="col-md-2  m-3">
          <label className="control-label" for="buttonColor">
            Weight
          </label>
          <div>
            <Select
              variant="standard"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={fontObject.weight}
              label="Size"
              onChange={(e) => setFontObject((font) => ({ ...font, weight: e.target.value }))}
            >
              {range(200, 800, 100).map((size) => (
                <MenuItem value={`${size}`}>{size}</MenuItem>
              ))}
            </Select>
          </div>
        </div>
      )}

      {fontObject.family && (
        <div className="col-md-2  m-3">
          <label className="control-label" for="buttonColor">
            Family
          </label>
          <div>
            <Select
              variant="standard"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={fontObject.family}
              label="Hover Effect"
              onChange={(e) => setFontObject((font) => ({ ...font, family: e.target.value }))}
            >
              <MenuItem value={"Hind"}>Hind</MenuItem>
              <MenuItem value={"Roboto"}>Roboto</MenuItem>
              <MenuItem value={"Open Sans"}>Open Sans</MenuItem>
              <MenuItem value={"Lato"}>Lato</MenuItem>
              <MenuItem value={"Montserrat"}>Montserrat</MenuItem>
              <MenuItem value={"Oswald"}>Oswald</MenuItem>
              <MenuItem value={"Georgia"}>Georgia</MenuItem>
            </Select>
          </div>
        </div>
      )}
      {children}
    </div>
  );
}
export default FontSelection;
