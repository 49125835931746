function generateFontStyle(fontConfig) {
  let fontStyle = "";
  if (fontConfig.size) {
    fontStyle = `${fontStyle}
        font-size: ${fontConfig.size}px !important;`;
  }

  if (fontConfig.color) {
    fontStyle = `${fontStyle}
        color: ${fontConfig.color} !important;`;
  }

  if (fontConfig.family) {
    fontStyle = `${fontStyle}
        font-family: ${fontConfig.family} !important;`;
  }

  if (fontConfig.weight) {
    fontStyle = `${fontStyle}
        font-weight: ${fontConfig.weight} !important;
        `;
  }
  return fontStyle;
}

export default generateFontStyle;
