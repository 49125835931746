import { useEffect } from "react";
function AgentSelectionStep() {
  useEffect(() => {
    let stepName = "buying-broker-select-2b";
    let allInputElems = document.querySelectorAll(`[name='${stepName}']`);
    const resetSelectedStatus = () =>
      allInputElems.forEach((inputElem) => {
        inputElem.closest(".choice").classList.remove("selected");
        document.getElementById(`note_${inputElem.id}`) && document.getElementById(`note_${inputElem.id}`).classList.remove("selected");
      });
    allInputElems.forEach((inputElem) => {
      let choiceDiv = inputElem.closest(`.choice`);
      choiceDiv.addEventListener("click", () => {
        resetSelectedStatus();
        inputElem.checked = true;
        choiceDiv.classList.add("selected");
        document.getElementById(`note_${inputElem.id}`) && document.getElementById(`note_${inputElem.id}`).classList.add("selected");
      });
    });
  }, []);

  return (
    <div>
      <div id="mainwrap-wrapper">
        <div className="container-fluid">
          <div className="row sub-container">
            <div data-wow-delay="none" className="wow none col-sm-12 c1 animated" style={{ visibility: "visible", padding: "0px" }}>
              <div className="hhs-rich-text">
                <div id="mainwrap" className="disabletabs">
                  <div id="containerIdHandlebar" className>
                    <form id="formIdHandlebar" className="match-step">
                      <div className="progressbar" style={{ whiteSpace: "nowrap" }}>
                        <div className="progressbar-inner" style={{ width: "69.23076923076923%" }}>
                          &nbsp;
                        </div>
                      </div>
                      <div id="innerwrap">
                        <div id data-property="buying-broker-select-2b" className="step match-step radio-step" data-jump>
                          <div className="step-inner">
                            <div className="row">
                              <div className="col-12 text-header step-title">We found your agent match!</div>
                              {/*<div class="col-12 text-header step-title" style="line-height:1.5em;">Here is your match<br/>for a <b style="border-bottom:3px solid #4EC3E0">Buyer Agent</b></div>*/}
                              <div className="col-12 text-header step-description">
                                Based on your criteria, we've matched with a real estate agent from the following top performing brokerage:
                              </div>
                            </div>
                            <div className="choicewrap wide row align-items-center" style={{ margin: "0 auto !important" }}>
                              <div className="col-xs-12 col-md-6" style={{ padding: ".5em" }}>
                                <div className="row choice selected " style={{ height: "100%" }}>
                                  <img className="relogo" src="https://www.dwellful.com/hs-fs/hubfs/optimized/relogo150x75/_.png" style={{ position: "absolute", right: "15px" }} />
                                  <div className="col-12" style={{ paddingRight: "130px" }}>
                                    <input id="buying-broker-select-2b_0" type="radio" name="buying-broker-select-2b" defaultValue="msi_4" defaultChecked />
                                    <label htmlFor="buying-broker-select-2b_0">Douglas Elliman Real Estate</label>
                                  </div>
                                  <div className="col-12" style={{ padding: ".5em 0" }}></div>
                                  <div className="col-12 text-center" style={{ paddingBottom: ".5em" }}>
                                    <button id="select_next_" className="hs-button primary large select_next">
                                      Introduce Me to This Agent
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xs-12 col-md-6 content-item" style={{ padding: ".5em" }}>
                                <div className="row text-left" style={{ padding: ".5em 0" }}>
                                  <div className="col-2 icon">
                                    <i className="fas fa-hand-holding-usd" />
                                  </div>
                                  <div className="col-10">
                                    <div className="text-header">Earn cash back</div>
                                    <div className="text-content">You get cash back when you close with the agent we match you with.</div>
                                  </div>
                                </div>
                                <div className="row text-left" style={{ padding: ".5em 0" }}>
                                  <div className="col-2 icon">
                                    <i className="fas fa-hand-peace" />
                                  </div>
                                  <div className="col-10">
                                    <div className="text-header">Satisfaction guarantee</div>
                                    <div className="text-content">If you’re unhappy with your agent for any reason, we’ll match you with a new one.</div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 text-header match-show-toggler hide">
                                Interested in seeing more options? No problem!{" "}
                                <a href="javascript:void(0)" id="show-match-toggle">
                                  Click here
                                </a>{" "}
                                to see more real estate agents that meet your needs.
                              </div>
                              <div className="col-xs-12 col-md-6 match-show" style={{ padding: ".5em" }}>
                                <div className="row choice " style={{ height: "100%" }}>
                                  <img className="relogo" src="https://www.dwellful.com/hs-fs/hubfs/optimized/relogo150x75/_.png" style={{ position: "absolute", right: "15px" }} />
                                  <div className="col-12" style={{ paddingRight: "130px" }}>
                                    <input id="buying-broker-select-2b_2" type="radio" name="buying-broker-select-2b" defaultValue="msi_2" />
                                    <label htmlFor="buying-broker-select-2b_2">SNG Real Estate</label>
                                  </div>
                                  <div className="col-12" style={{ padding: ".5em 0" }}>
                                    <div>
                                      <b className="broker-highlight">
                                        <i className="fas fa-user-tie" />
                                      </b>
                                      <b>Agent: </b>Steven Gershman
                                    </div>
                                  </div>
                                  <div className="col-12 text-center" style={{ paddingBottom: ".5em" }}>
                                    <button id="select_next_" className="hs-button primary large select_next">
                                      Introduce Me to This Agent
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xs-12 col-md-6 match-show" style={{ padding: ".5em" }}>
                                <div className="row choice " style={{ height: "100%" }}>
                                  <img className="relogo" src="https://www.dwellful.com/hs-fs/hubfs/optimized/relogo150x75/_.png" style={{ position: "absolute", right: "15px" }} />
                                  <div className="col-12" style={{ paddingRight: "130px" }}>
                                    <input id="buying-broker-select-2b_3" type="radio" name="buying-broker-select-2b" defaultValue="msi_11" />
                                    <label htmlFor="buying-broker-select-2b_3">Weichert Realtors</label>
                                  </div>
                                  <div className="col-12" style={{ padding: ".5em 0" }}></div>
                                  <div className="col-12 text-center" style={{ paddingBottom: ".5em" }}>
                                    <button id="select_next_" className="hs-button primary large select_next">
                                      Introduce Me to This Agent
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="row align-items-center step-note">
                                {" "}
                                <div className="col-1 info-icon">
                                  <i className="fas fa-info-circle" />
                                </div>
                                <div className="col-11 content">
                                  We work with the best agents from the top brokerages across the US. We use information you provided paired with transaction data and consumer
                                  feedback to select the perfect agent for your needs.
                                </div>
                              </div>
                            </div>
                            <div className="row button-row">
                              <div className="col-12">
                                <a href="javascript:void(0)" className="back">
                                  ◄ Back
                                </a>
                                <button id="next_buying-broker-select-2b" className="hs-button primary large next">
                                  Introduce Me
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 poweredby">
                        <span>Powered by &nbsp;</span>
                      </div>
                    </form>
                  </div>
                  <div id="loaderIdHandlebar" className="loader-wrapper hide">
                    <form id="formIdHandlebar" className="content-step">
                      <div className="progressbar" style={{ whiteSpace: "nowrap" }}>
                        <div className="progressbar-inner" style={{ width: "69.23076923076923%" }}>
                          &nbsp;
                        </div>
                      </div>
                      <div id="innerwrap">
                        <div id className="step content-step">
                          <div className="step-inner">
                            <div className="row">
                              <div className="col-12 text-header step-title">We are reviewing your information</div>
                            </div>
                            <div className="row">
                              <div className="col-12 step-loader">
                                <div className="loader">Loading...</div>Analyzing agent network for best match...
                              </div>
                            </div>
                            <div className="row"></div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 poweredby">
                        <span>Powered by &nbsp;</span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pac-container pac-logo" style={{ width: "0px", position: "absolute", left: "0px", top: "0px", display: "none" }} />
    </div>
  );
}

export default AgentSelectionStep;
