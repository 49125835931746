function LandingStep() {
  return (
    <div id="mainwrap-wrapper">
      <div className="container-fluid">
        <div className="row sub-container">
          <div data-wow-delay="none" className="wow none col-sm-12 c1 animated" style={{ visibility: "visible", padding: "0px" }}>
            <div className="hhs-rich-text">
              <div id="mainwrap" className="disabletabs">
                <div id="containerIdHandlebar" className>
                  <form id="formIdHandlebar">
                    <div className="widget-type-widget_container white-bg" style={{}} data-widget-type="widget_container" data-x={0} data-w={12}>
                      <div className="progressbar" style={{ whiteSpace: "nowrap" }}>
                        <div className="progressbar-inner">&nbsp;</div>
                      </div>
                      <div id="innerwrap" style={{ padding: "0%" }}>
                        <div id="survey-injection">
                          <div id="starttype" data-property="type" className="step starttype-step">
                            <div className="splash-bkg" />
                            <div className="step-inner" style={{ padding: "1em 0px", left: "0px" }}>
                              <div className="row">
                                <div id="mainstart" className="col-12" style={{ padding: "1em 0 0 0" }}>
                                  <div className="choicewrap wide" style={{ position: "relative" }}>
                                    <div className="row" style={{ padding: "0 0 0 1em" }}>
                                      <div className="col-12   text-left" style={{ padding: "0 15px .5em 15px" }}>
                                        <h1 className="landing-startline " style={{ textAlign: "left", margin: 0 }}>
                                          Find a top local real estate agent.
                                          <br />
                                          <span style={{ fontSize: ".7em", fontWeight: "normal" }}>Get cash back after you close.</span>
                                        </h1>
                                        <h2 className="landing-title hide-under-sm " style={{ textAlign: "left", margin: ".5em 0 0 0" }}>
                                          Free service&nbsp;&nbsp;●&nbsp;&nbsp;Satisfaction guaranteed
                                        </h2>
                                      </div>
                                    </div>
                                    <div className="row" style={{ padding: "0 0 .5em 1em" }}>
                                      <div className="hide-under-sm col-xs-12 col-md-6  step-description text-left">
                                        <h3 className="landing-subtitle ">
                                          Real estate is easier with an expert at your side. We'll find you one based on your needs. You'll get cash back at closing.
                                        </h3>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-xs-12 col-md-6 col-lg-5 col-xl-4">
                                        <div className="row">
                                          <div className="col-12 text-left-over-xs">
                                            <button data-value="buying" className="hs-button primary large nextchoice ">
                                              I am Buying
                                            </button>
                                            <button data-value="selling" className="hs-button primary large nextchoice ">
                                              I am Selling
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row" style={{ marginTop: "2em" }}>
                                      <div className="col-12 hide-over-sm  step-description text-left" style={{ marginTop: "0em", marginBottom: "0em", padding: "0 30px" }}>
                                        <h3 className="landing-subtitle mobile">
                                          Real estate is easier with an expert at your side. We'll find you one based on your needs. You'll get cash back at closing.
                                        </h3>
                                      </div>
                                      <div className="col-12 relogowrap-container">
                                        <div className="relogowrap">
                                          <img src="https://www.dwellful.com/hs-fs/hubfs/optimized/relogo150x75/crlk.png" className="relogo" />
                                          <img src="https://www.dwellful.com/hs-fs/hubfs/optimized/relogo150x75/hare.png" className="relogo" />
                                          <img src="https://www.dwellful.com/hs-fs/hubfs/optimized/relogo150x75/kewi.png" className="relogo" />
                                          <img src="https://www.dwellful.com/hs-fs/hubfs/optimized/relogo150x75/lfre.png" className="relogo" />
                                          <img src="https://www.dwellful.com/hs-fs/hubfs/optimized/relogo150x75/soth.png" className="relogo" />
                                          <img src="https://www.dwellful.com/hs-fs/hubfs/optimized/relogo150x75/envo.png" className="relogo" />
                                          <img src="https://www.dwellful.com/hs-fs/hubfs/optimized/relogo150x75/rlba.png" className="relogo" />
                                          <img src="https://www.dwellful.com/hs-fs/hubfs/optimized/relogo150x75/wira.png" className="relogo" />
                                          <img src="https://www.dwellful.com/hs-fs/hubfs/optimized/relogo150x75/hola.png" className="relogo" />
                                          <img src="https://www.dwellful.com/hs-fs/hubfs/optimized/relogo150x75/lave.png" className="relogo" />
                                          <img src="https://www.dwellful.com/hs-fs/hubfs/optimized/relogo150x75/berk.png" className="relogo" />
                                          <img src="https://www.dwellful.com/hs-fs/hubfs/optimized/relogo150x75/bhng.png" className="relogo" />
                                          <img src="https://www.dwellful.com/hs-fs/hubfs/optimized/relogo150x75/ce21.png" className="relogo" />
                                          <img src="https://www.dwellful.com/hs-fs/hubfs/optimized/relogo150x75/coba.png" className="relogo" />
                                          <img src="https://www.dwellful.com/hs-fs/hubfs/optimized/relogo150x75/cmps.png" className="relogo" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 poweredby">
                        <span>Powered by &nbsp;</span>
                      </div>
                    </div>
                  </form>
                </div>
                <div id="loaderIdHandlebar" className="loader-wrapper hide">
                  <form id="formIdHandlebar">
                    <div className="progressbar" style={{ whiteSpace: "nowrap" }}>
                      <div className="progressbar-inner" style={{ width: "5.263157894736842%" }}>
                        &nbsp;
                      </div>
                    </div>
                    <div id="innerwrap">
                      <div id className="step content-step">
                        <div className="step-inner">
                          <div className="row">
                            <div className="col-12  ">We are reviewing your information</div>
                          </div>
                          <div className="row">
                            <div className="col-12 step-loader">
                              <div className="loader">Loading...</div>Analyzing agent network for best match...
                            </div>
                          </div>
                          <div className="row"></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 poweredby">
                      <span>Powered by &nbsp;</span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingStep;
