import { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import useLocalStorage from "react-use-localstorage";
import { MenuItem, Select, Switch, Grid, Typography, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import FontSelection from "../../../components/fontSelection";
import ColorPickerCustom from "../../../components/colorPickr";
import Button from "@mui/material/Button";
import Highlight from "react-highlight";
import RangedSelect from "../../../components/rangedSelect";
import "../sample-step/css/bootstrap-grid.min.scss";

function CustomizationForm({ presetStates, setPresetStates, css, setPreviewPosition, setPromptNavigation, previewPosition }) {
  const {
    backgroundColor,
    secondaryBackgroundColor,
    primaryColor,
    buttonOptions,
    borderOptions,
    radioOptions,
    headerFont,
    subHeaderFont,
    footernoteFont,
    reviewFont,
    labelFont,
    textFont,
    logoPosition,
    progressbar,
    inputOptions,
    landingBackground,
    buttonFont,
    errorFont,
    radioSelectedFont,
    radioUnselectedFont,
    agentLogoOptions,
    landingHeadingFont,
    landingSubheadingFont,
    poweredByFont,
    contentItemHeaderFont,
    contentItemTextFont,
    brokerAddressFont,
    brokerNameFont,
    reviewAuthorFont,
    matchTogglerText,
    contactInfoTitleFont,
    contactInfoLabelFont,
    contactInfoValueFont,
    inputFieldFont,
  } = presetStates;

  const [storedPresets, setStoredPresets] = useLocalStorage("widgetPresets", "[]");
  setPromptNavigation(true);
  const [radioPreset, setRadioPreset] = useState("default");
  const [presetInputValue, setPresetInputValue] = useState("");
  useEffect(() => {
    applyRadioPreset(radioPreset);
  }, [primaryColor, secondaryBackgroundColor, borderOptions]);

  useEffect(() => {
    appplyInputPreset(inputPreset);
  }, [backgroundColor, primaryColor, secondaryBackgroundColor, borderOptions]);

  const {
    setBackgroundColor,
    setSecondaryBackgroundColor,
    setPrimaryColor,
    setButtonOptions,
    setBorderOptions,
    setRadioOptions,
    setHeaderFont,
    setSubHeaderFont,
    setFooternoteFont,
    setReviewFont,
    setLabelFont,
    setTextFont,
    setLogoPosition,
    setProgressbar,
    setInputOptions,
    setLandingBackground,
    setButtonFont,
    setErrorFont,
    setAgentLogoOptions,
    setRadioSelectedFont,
    setRadioUnselectedFont,
    setLandingHeadingFont,
    setLandingSubheadingFont,
    setPoweredByFont,
    setContentItemHeaderFont,
    setContentItemTextFont,
    setBrokerAddressFont,
    setBrokerNameFont,
    setReviewAuthorFont,
    setMatchTogglerText,
    setContactInfoTitleFont,
    setContactInfoLabelFont,
    setContactInfoValueFont,
    setInputFieldFont,
  } = setPresetStates;

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const applyPresetState = (newPresetStates) => {
    let newPresetStatesObject = JSON.parse(newPresetStates);
    Object.keys(newPresetStatesObject).forEach((key) => {
      setPresetStates[`set${capitalizeFirstLetter(key)}`](newPresetStatesObject[key]);
    });
  };

  const alertUser = (event) => {
    event.preventDefault();
    event.returnValue = "";
  };

  //useEffect(() => setPresetDetails(presetStates), [Object.values(presetStates)]);

  function applyRadioPreset(preset) {
    switch (preset) {
      case "default": {
        setRadioOptions({ borderColor: borderOptions.color, selectedBorderColor: primaryColor, selectedBackgroundColor: secondaryBackgroundColor, borderSize: borderOptions.size });
        break;
      }
      case "alt": {
        setRadioOptions({ borderColor: borderOptions.color, selectedBorderColor: primaryColor, selectedBackgroundColor: backgroundColor, borderSize: 0 });
        break;
      }
      case "custom": {
      }
    }
  }

  const [inputPreset, setInputPreset] = useState("default");

  function appplyInputPreset(preset) {
    switch (preset) {
      case "default": {
        setInputOptions({ borderType: "full", borderColor: borderOptions.color, borderSize: borderOptions.size, backgroundColor: secondaryBackgroundColor });
        setLabelFont((labelFont) => ({ ...labelFont, borderSize: 1 }));
        break;
      }
      case "alt": {
        setInputOptions({ borderType: "bottom", borderColor: "#000000", borderSize: borderOptions.size, backgroundColor: backgroundColor });
        setLabelFont((labelFont) => ({ ...labelFont, borderSize: 0 }));
        break;
      }
      case "custom": {
      }
    }
  }

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [presetName, setPresetName] = useState("");
  const handleSavePreset = (presetName) => {
    if (JSON.parse(storedPresets).find((preset) => preset.name == presetName)) {
      window.alert("Preset Wwth same name already exists!");
      return;
    }
    setStoredPresets(JSON.stringify([...JSON.parse(storedPresets), { name: presetName, value: JSON.parse(presetInputValue) }]));
    handleClose();
  };
  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Save Preset</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter Preset Name and Save.</DialogContentText>
          <TextField
            value={presetName}
            onChange={(e) => setPresetName(e.target.value)}
            autoFocus
            margin="dense"
            id="name"
            label="Preset Name"
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => handleSavePreset(presetName)}>Save</Button>
        </DialogActions>
      </Dialog>

      <Tabs style={{ overflow: "auto" }}>
        <TabList>
          <Tab>Form</Tab>
          <Tab onClick={() => setPresetInputValue(JSON.stringify(presetStates))}>Presets</Tab>
          <Tab>Custom Css</Tab>
          <div style={{ display: "inline", margin: "4em" }} className="m-5">
            <label className="control-label" for="buttonColor">
              Preview Position
            </label>
            <Select
              sx={{ m: 3 }}
              variant="standard"
              className="m-3"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={previewPosition}
              label="Preview Position"
              onChange={(e) => setPreviewPosition(e.target.value)}
            >
              <MenuItem value={"vertical"}>Bottom</MenuItem>
              <MenuItem value={"horizontal"}>Right</MenuItem>
              <MenuItem value={"hidden"}>Hidden</MenuItem>
            </Select>
          </div>
        </TabList>

        <TabPanel>
          <form
            // className="form-horizontal"
            style={{ height: `80vh`, overflow: "auto" }}
          >
            <div className=" row col-md-12  m-3">
              <div className="col-md-3  m-3">
                <label className="control-label" for="buttonColor">
                  Primary/Buttton Color
                </label>

                <ColorPickerCustom value={primaryColor} onChange={(color) => setPrimaryColor(color)}></ColorPickerCustom>
              </div>

              <div className="col-md-3  m-3">
                <label className="control-label" for="backgroundColor">
                  Primary Background Color
                </label>
                <ColorPickerCustom value={backgroundColor} onChange={(color) => setBackgroundColor(color)}></ColorPickerCustom>
              </div>

              <div className="col-md-3  m-3">
                <label className="control-label" for="backgroundColor">
                  Secondary Background Color
                </label>
                <ColorPickerCustom value={secondaryBackgroundColor} onChange={(color) => setSecondaryBackgroundColor(color)}></ColorPickerCustom>
              </div>
            </div>
            <div className="row form-group  m-3">
              <label className="col-md-12 control-label">Landing Background</label>
              <div className="col-md-3  m-3">
                <label className="col-md-12 control-label" for="backgroundColor">
                  Image
                </label>
                <div>
                  <Select
                    variant="standard"
                    value={landingBackground.imgUrl}
                    onChange={(e) => setLandingBackground((landingBackground) => ({ ...landingBackground, imgUrl: e.target.value }))}
                  >
                    <MenuItem value={"https://www.dwellful.com/hubfs/tools/fpbkg_10.jpg"}>Dream Home</MenuItem>
                    <MenuItem value={"https://www.dwellful.com/hubfs/tools/fpbkg_20.jpg"}>Handshake</MenuItem>
                    <MenuItem value={""}>None</MenuItem>
                  </Select>
                </div>
              </div>

              <div className="col-md-3  m-3">
                <label className="control-label" for="buttonColor">
                  Opacity
                </label>
                <div>
                  <Select
                    variant="standard"
                    value={landingBackground.opacity}
                    onChange={(e) => setLandingBackground((landingBackground) => ({ ...landingBackground, opacity: e.target.value }))}
                  >
                    <MenuItem value={"0.25"}>25%</MenuItem>
                    <MenuItem value={"0.50"}>50%</MenuItem>
                    <MenuItem value={"0.75"}>75%</MenuItem>
                    <MenuItem value={"1.0"}>100%</MenuItem>
                  </Select>
                </div>
              </div>
            </div>
            <div className="row form-group  m-3">
              <label className="col-md-12 control-label">Agent Logo Options</label>
              <div className="col-md-3  m-3">
                <label className="col-md-12 control-label" for="backgroundColor">
                  Display
                </label>
                <div>
                  <Select variant="standard" value={agentLogoOptions.display} onChange={(e) => setAgentLogoOptions((logoOptions) => ({ ...logoOptions, display: e.target.value }))}>
                    <MenuItem value={"block"}>Yes</MenuItem>
                    <MenuItem value={"none"}>No</MenuItem>
                  </Select>
                </div>
              </div>

              <div className="col-md-3  m-3">
                <label className="control-label" for="buttonColor">
                  Color/Tint
                </label>
                <div>
                  <ColorPickerCustom value={agentLogoOptions.color} onChange={(color) => setAgentLogoOptions((logoOptions) => ({ ...logoOptions, color }))}></ColorPickerCustom>
                </div>
              </div>
            </div>
            <div className="row form-group  m-3">
              <label className="col-md-12 control-label">Logo Options</label>

              <div className="col-md-3  m-3">
                <label className="col-md-12 control-label" for="backgroundColor">
                  Background Color
                </label>
                <div className="col-md-12">
                  <ColorPickerCustom
                    value={logoPosition.backgroundColor}
                    onChange={(color) => setLogoPosition((logoPosition) => ({ ...logoPosition, backgroundColor: color }))}
                  ></ColorPickerCustom>
                </div>
              </div>

              <div className="col-md-2  m-3">
                <label className="control-label" for="buttonColor">
                  Vertical Position
                </label>
                <div>
                  <Select
                    variant="standard"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={logoPosition.verticalOrder}
                    label="Hover Effect"
                    onChange={(e) => setLogoPosition((logo) => ({ ...logo, verticalOrder: e.target.value }))}
                  >
                    <MenuItem value={2}>Top</MenuItem>
                    <MenuItem value={5}>Bottom</MenuItem>
                  </Select>
                </div>
              </div>

              <div className="col-md-3  m-3">
                <label className="control-label" for="buttonColor">
                  Horizontal Position
                </label>
                <div>
                  <Select
                    variant="standard"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={logoPosition.horizontal}
                    label="Hover Effect"
                    onChange={(e) => setLogoPosition((logo) => ({ ...logo, horizontal: e.target.value }))}
                  >
                    <MenuItem value={"left"}>Left</MenuItem>
                    <MenuItem value={"center"}>Center</MenuItem>
                    <MenuItem value={"right"}>Right</MenuItem>
                  </Select>
                </div>
              </div>

              <div className="col-md-2  m-3">
                <label className="control-label" for="buttonColor">
                  Theme
                </label>
                <div>
                  <Select
                    variant="standard"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={logoPosition.theme}
                    label="Hover Effect"
                    onChange={(e) => setLogoPosition((logo) => ({ ...logo, theme: e.target.value }))}
                  >
                    <MenuItem value={"black"}>Black</MenuItem>
                    <MenuItem value={"white"}>White</MenuItem>
                  </Select>
                </div>
              </div>
            </div>
            <div className="row form-group  m-3">
              <label className="col-md-12 control-label">Radio Options</label>

              <div className="col-md-3  m-3">
                <label className="col-md-12 control-label" for="backgroundColor">
                  Preset
                </label>
                <div className="col-md-12">
                  <Select
                    variant="standard"
                    value={radioPreset}
                    onChange={(e) => {
                      setRadioPreset(e.target.value);
                      applyRadioPreset(e.target.value);
                    }}
                  >
                    <MenuItem value="default">Default</MenuItem>
                    <MenuItem value="alt">Alternate</MenuItem>
                    <MenuItem value="custom">Custom</MenuItem>
                  </Select>
                </div>
              </div>
              {radioPreset == "custom" && (
                <>
                  <div className="col-md-3  m-3">
                    <label className="col-md-12 control-label" for="backgroundColor">
                      Selected Background Color
                    </label>
                    <div className="col-md-12">
                      <ColorPickerCustom
                        value={radioOptions.selectedBackgroundColor}
                        onChange={(color) => setRadioOptions((radioOptions) => ({ ...radioOptions, selectedBackgroundColor: color }))}
                      ></ColorPickerCustom>
                    </div>
                  </div>

                  <div className="col-md-3  m-3">
                    <label className="col-md-12 control-label" for="backgroundColor">
                      Border Color
                    </label>
                    <div className="col-md-12">
                      <ColorPickerCustom
                        value={radioOptions.borderColor}
                        onChange={(color) => setRadioOptions((radioOptions) => ({ ...radioOptions, borderColor: color }))}
                      ></ColorPickerCustom>
                    </div>
                  </div>

                  <div className="col-md-3  m-3">
                    <label className="col-md-12 control-label" for="backgroundColor">
                      Selected Border Color
                    </label>
                    <div className="col-md-12">
                      <ColorPickerCustom
                        value={radioOptions.selectedBorderColor}
                        onChange={(color) => setRadioOptions((radioOptions) => ({ ...radioOptions, selectedBorderColor: color }))}
                      ></ColorPickerCustom>
                    </div>
                  </div>

                  <div className="col-md-3  m-3">
                    <label className="control-label" for="buttonColor">
                      Border Size
                    </label>
                    <div>
                      <RangedSelect
                        range={{ start: 0, end: 4 }}
                        value={radioOptions.borderSize}
                        onChange={(e) => setRadioOptions((radioOptions) => ({ ...radioOptions, borderSize: e.target.value }))}
                      ></RangedSelect>
                    </div>
                  </div>
                  <FontSelection
                    title={"Radio Selected Font"}
                    fontObject={radioSelectedFont}
                    setFontObject={setRadioSelectedFont}
                    fontRange={{ start: 10, end: 28 }}
                  ></FontSelection>
                  <FontSelection
                    title={"Radio Unselected Font"}
                    fontObject={radioUnselectedFont}
                    setFontObject={setRadioUnselectedFont}
                    fontRange={{ start: 10, end: 28 }}
                  ></FontSelection>
                </>
              )}
            </div>
            <div className="row form-group  m-3">
              <label className="col-md-12 control-label">Input Options</label>
              <div className="col-md-3  m-3">
                <label className="col-md-12 control-label" for="backgroundColor">
                  Preset
                </label>
                <div className="col-md-12">
                  <Select
                    variant="standard"
                    value={inputPreset}
                    onChange={(e) => {
                      setInputPreset(e.target.value);
                      appplyInputPreset(e.target.value);
                    }}
                  >
                    <MenuItem value="default">Default</MenuItem>
                    <MenuItem value="alt">Alternate</MenuItem>
                    <MenuItem value="custom">Custom</MenuItem>
                  </Select>
                </div>
              </div>
              {inputPreset == "custom" && (
                <>
                  <div className="col-md-3  m-3">
                    <label className="col-md-12 control-label" for="backgroundColor">
                      Background Color
                    </label>
                    <div className="col-md-12">
                      <ColorPickerCustom
                        value={inputOptions.backgroundColor}
                        onChange={(color) => setInputOptions((inputOptions) => ({ ...inputOptions, backgroundColor: color }))}
                      ></ColorPickerCustom>
                    </div>
                  </div>

                  <div className="col-md-3  m-3">
                    <label className="col-md-12 control-label" for="backgroundColor">
                      Border Color
                    </label>
                    <div className="col-md-12">
                      <ColorPickerCustom
                        value={inputOptions.borderColor}
                        onChange={(color) => setInputOptions((inputOptions) => ({ ...inputOptions, borderColor: color }))}
                      ></ColorPickerCustom>
                    </div>
                  </div>
                  <div className="col-md-3  m-3">
                    <label className="control-label" for="buttonColor">
                      Border Type
                    </label>
                    <div>
                      <Select
                        variant="standard"
                        value={inputOptions.borderType}
                        onChange={(e) => setInputOptions((inputOptions) => ({ ...inputOptions, borderType: e.target.value }))}
                      >
                        <MenuItem value={"full"}>Full</MenuItem>
                        <MenuItem value={"bottom"}>Only Bottom</MenuItem>
                      </Select>
                    </div>
                  </div>

                  <div className="col-md-3  m-3">
                    <label className="control-label" for="buttonColor">
                      Border Size
                    </label>
                    <div>
                      <RangedSelect
                        range={{ start: 0, end: 4 }}
                        value={inputOptions.borderSize}
                        onChange={(e) => setInputOptions((inputOptions) => ({ ...inputOptions, borderSize: e.target.value }))}
                      ></RangedSelect>
                    </div>
                  </div>
                  <FontSelection title={"Input Field Font"} fontObject={inputFieldFont} setFontObject={setInputFieldFont} fontRange={{ start: 10, end: 28 }}></FontSelection>
                </>
              )}
            </div>
            <div className="row form-group  m-3">
              <label className="col-md-12 control-label">Progress Bar Options</label>

              <div className="col-md-2  m-3">
                <label className="control-label" for="buttonColor">
                  Color
                </label>
                <div>
                  <ColorPickerCustom value={progressbar.color} onChange={(color) => setProgressbar((progressbar) => ({ ...progressbar, color }))}></ColorPickerCustom>
                </div>
              </div>

              <div className="col-md-3  m-3">
                <label className="control-label" for="buttonColor">
                  Background Color
                </label>
                <div>
                  <ColorPickerCustom
                    value={progressbar.backgroundColor}
                    onChange={(color) => setProgressbar((progressbar) => ({ ...progressbar, backgroundColor: color }))}
                  ></ColorPickerCustom>
                </div>
              </div>

              <div className="col-md-3  m-3">
                <label className="control-label" for="buttonColor">
                  Shape
                </label>
                <div>
                  <Select
                    variant="standard"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={progressbar.radius}
                    label="Shape"
                    onChange={(e) => setProgressbar((progressbar) => ({ ...progressbar, radius: e.target.value }))}
                  >
                    <MenuItem value={0}>Square</MenuItem>
                    <MenuItem value={10}>Circle</MenuItem>
                  </Select>
                </div>
              </div>

              <div className="col-md-3  m-3">
                <label className="control-label" for="buttonColor">
                  Margin
                </label>
                <div>
                  <Select
                    variant="standard"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={progressbar.margin}
                    label="Shape"
                    onChange={(e) => setProgressbar((progressbar) => ({ ...progressbar, margin: e.target.value }))}
                  >
                    <MenuItem value={0}>0px</MenuItem>
                    <MenuItem value={5}>5px</MenuItem>
                    <MenuItem value={10}>10px</MenuItem>
                    <MenuItem value={15}>15px</MenuItem>
                    <MenuItem value={20}>20px</MenuItem>
                    <MenuItem value={25}>25px</MenuItem>
                    <MenuItem value={30}>30px</MenuItem>
                    <MenuItem value={35}>35px</MenuItem>
                  </Select>
                </div>
              </div>

              <div className="col-md-3  m-3">
                <label className="control-label" for="buttonColor">
                  Width
                </label>
                <div>
                  <Select
                    variant="standard"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={progressbar.width}
                    label="Hover Effect"
                    onChange={(e) => setProgressbar((progressbar) => ({ ...progressbar, width: e.target.value }))}
                  >
                    <MenuItem value={100}>Full</MenuItem>
                    <MenuItem value={80}>80%</MenuItem>
                    <MenuItem value={50}>50%</MenuItem>
                  </Select>
                </div>
              </div>

              <div className="col-md-3  m-3">
                <label className="control-label" for="buttonColor">
                  Vertical Position
                </label>
                <div>
                  <Select
                    disabled={logoPosition.verticalOrder == 5}
                    variant="standard"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={progressbar.order}
                    label="Hover Effect"
                    onChange={(e) => setProgressbar((logo) => ({ ...logo, order: e.target.value }))}
                  >
                    <MenuItem value={1}>Above Logo</MenuItem>
                    <MenuItem value={3}>Below Logo</MenuItem>
                  </Select>
                </div>
              </div>
            </div>

            <div className="row form-group  m-3">
              <label className="col-md-12 control-label ">Button Options</label>

              <div className="col-md-2  m-3">
                <label className="control-label" for="buttonColor">
                  Hover Effect
                </label>
                <div>
                  <Select
                    variant="standard"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={buttonOptions.hoverEffect}
                    label="Hover Effect"
                    onChange={(e) => setButtonOptions((button) => ({ ...button, hoverEffect: e.target.value }))}
                  >
                    <MenuItem value={"zoom"}>Zoom</MenuItem>
                    <MenuItem value={"invert"}>Invert</MenuItem>
                  </Select>
                </div>
              </div>

              <div className="col-md-2  m-3">
                <label className="control-label" for="buttonColor">
                  Button Shadow
                </label>
                <div>
                  <Switch
                    value={buttonOptions.shadow}
                    checked={buttonOptions.shadow}
                    onChange={(e, v) => setButtonOptions((button) => ({ ...button, shadow: v }))}
                    inputProps={{ "aria-label": "" }}
                  />
                </div>
              </div>

              <div className="col-md-2  m-3">
                <label className="control-label" for="buttonColor">
                  Shape
                </label>
                <div>
                  <Select
                    variant="standard"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={buttonOptions.radius}
                    label="Hover Effect"
                    onChange={(e) => setButtonOptions((button) => ({ ...button, radius: e.target.value }))}
                  >
                    <MenuItem value={0}>Square</MenuItem>
                    <MenuItem value={15}>Rounded</MenuItem>
                    <MenuItem value={50}>Circle</MenuItem>
                  </Select>
                </div>

                {/* <Slider value={buttonRadius} onChange={(e, v) => setButtonRadius(v)} aria-labelledby="" min={0} max={100} /> */}
              </div>
            </div>
            <FontSelection title={"Landing Header Font"} fontObject={landingHeadingFont} setFontObject={setLandingHeadingFont} fontRange={{ start: 10, end: 28 }}></FontSelection>
            <FontSelection
              title={"Landing Subeader Font"}
              fontObject={landingSubheadingFont}
              setFontObject={setLandingSubheadingFont}
              fontRange={{ start: 10, end: 28 }}
            ></FontSelection>
            <FontSelection title={"PoweredBy Font"} fontObject={poweredByFont} setFontObject={setPoweredByFont} fontRange={{ start: 10, end: 28 }}></FontSelection>
            <FontSelection title={"Header Font"} fontObject={headerFont} setFontObject={setHeaderFont} fontRange={{ start: 10, end: 28 }}></FontSelection>
            <FontSelection title={"Sub-header Font"} fontObject={subHeaderFont} setFontObject={setSubHeaderFont} fontRange={{ start: 10, end: 28 }}></FontSelection>
            <FontSelection title={"Button Font"} fontObject={buttonFont} setFontObject={setButtonFont} fontRange={{ start: 10, end: 28 }}></FontSelection>
            <FontSelection title={"Step Note/Footernote Font"} fontObject={footernoteFont} setFontObject={setFooternoteFont} fontRange={{ start: 10, end: 28 }}></FontSelection>
            <FontSelection title={"Broker Name Font"} fontObject={brokerNameFont} setFontObject={setBrokerNameFont} fontRange={{ start: 10, end: 28 }}></FontSelection>
            <FontSelection title={"Broker Address Font"} fontObject={brokerAddressFont} setFontObject={setBrokerAddressFont} fontRange={{ start: 10, end: 28 }}></FontSelection>
            <FontSelection
              title={"Contact Info Title Font"}
              fontObject={contactInfoTitleFont}
              setFontObject={setContactInfoTitleFont}
              fontRange={{ start: 10, end: 28 }}
            ></FontSelection>
            <FontSelection
              title={"Contact Info Label Font"}
              fontObject={contactInfoLabelFont}
              setFontObject={setContactInfoLabelFont}
              fontRange={{ start: 10, end: 28 }}
            ></FontSelection>
            <FontSelection
              title={"Contact Info Value Font"}
              fontObject={contactInfoValueFont}
              setFontObject={setContactInfoValueFont}
              fontRange={{ start: 10, end: 28 }}
            ></FontSelection>
            <FontSelection title={"Review Font"} fontObject={reviewFont} setFontObject={setReviewFont} fontRange={{ start: 10, end: 28 }}></FontSelection>
            <FontSelection title={"Review Author Font"} fontObject={reviewAuthorFont} setFontObject={setReviewAuthorFont} fontRange={{ start: 10, end: 28 }}></FontSelection>
            <FontSelection title={"Label Font"} fontObject={labelFont} setFontObject={setLabelFont} fontRange={{ start: 10, end: 28 }}>
              <div className="col-md-1  m-3">
                <label className="control-label" for="buttonColor">
                  Backg. Color
                </label>

                <ColorPickerCustom value={labelFont.backgroundColor} onChange={(color) => setLabelFont((font) => ({ ...font, backgroundColor: color }))}></ColorPickerCustom>
              </div>
              <div className="col-md-1  m-3">
                <label className="control-label" for="buttonColor">
                  Border Size
                </label>

                <RangedSelect
                  range={{ start: 0, end: 4 }}
                  value={labelFont.borderSize}
                  onChange={(e) => setLabelFont((labelFont) => ({ ...labelFont, borderSize: e.target.value }))}
                ></RangedSelect>
              </div>
            </FontSelection>
            <FontSelection
              title={"Content Item Heading"}
              fontObject={contentItemHeaderFont}
              setFontObject={setContentItemHeaderFont}
              fontRange={{ start: 10, end: 28 }}
            ></FontSelection>
            <FontSelection
              title={"Content Item Description"}
              fontObject={contentItemTextFont}
              setFontObject={setContentItemTextFont}
              fontRange={{ start: 10, end: 28 }}
            ></FontSelection>

            <FontSelection title={"Match Show Toggler Font"} fontObject={matchTogglerText} setFontObject={setMatchTogglerText} fontRange={{ start: 10, end: 28 }}></FontSelection>
            <FontSelection title={"Error Font"} fontObject={errorFont} setFontObject={setErrorFont} fontRange={{ start: 10, end: 28 }}></FontSelection>
            {/* <FontSelection title={"Other Text Font"} fontObject={textFont} setFontObject={setTextFont} fontRange={{ start: 10, end: 28 }}></FontSelection> */}
            <FontSelection title={"Border Options"} fontObject={borderOptions} setFontObject={setBorderOptions} fontRange={{ start: 0, end: 4 }}></FontSelection>
          </form>
        </TabPanel>
        <TabPanel>
          <div style={{ whiteSpace: "break-spaces", height: `84vh`, overflow: "auto" }}>
            <Button variant="outlined" color="primary" onClick={() => applyPresetState(presetInputValue)}>
              Apply Preset
            </Button>

            <Button
              sx={{ ml: 3 }}
              variant="outlined"
              color="primary"
              // onClick={() => {
              //   setStoredPresets(JSON.stringify([...JSON.parse(storedPresets), { name: "test", value: JSON.parse(presetInputValue) }]));
              // }}
              onClick={() => setOpen(true)}
            >
              Save Preset
            </Button>

            <Select
              sx={{ m: 3 }}
              variant="standard"
              className="m-3"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              //value={previewPosition}
              label="Preview Position"
              onChange={(e) => {
                setPresetInputValue(JSON.stringify(JSON.parse(storedPresets).find((preset) => preset.name == e.target.value).value));
              }}
            >
              {JSON.parse(storedPresets).map((preset) => (
                <MenuItem value={preset.name}>{preset.name}</MenuItem>
              ))}
            </Select>

            {/* <Button sx={{ ml: 3 }} variant="outlined" color="primary" onClick={() => setPresetStates(presetInputValue)}>
              Load Preset
            </Button> */}

            {/* <Highlight> */}
            <Typography sx={{ mt: 1 }} variant="body1"></Typography>
            <TextField
              id="outlined-multiline-static"
              label="Preset JSON"
              multiline
              rows={15}
              value={presetInputValue}
              onChange={(e) => setPresetInputValue(e.target.value)}
              fullWidth
              sx={{ mt: 1 }}
            />
            {/* </Highlight> */}
          </div>
        </TabPanel>
        <TabPanel>
          <div style={{ whiteSpace: "break-spaces", height: `84vh`, overflow: "auto" }}>
            <Grid container justifyContent={"center"}>
              <Button variant="outlined" color="primary" onClick={() => navigator.clipboard.writeText(css)}>
                Copy to Clipboard
              </Button>
            </Grid>
            <Highlight>{css}</Highlight>
          </div>
        </TabPanel>
      </Tabs>
    </>
  );
}

export default CustomizationForm;
