import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { FormControl } from "@mui/material";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import { Select, MenuItem, InputLabel, Tooltip, IconButton } from "@mui/material";
import generateScript from "./generateScript";
import { useState, useEffect } from "react";
import Highlight from "react-highlight";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

function ScriptForm() {
  const [iid, setIid] = useState("");
  const [eid, setEid] = useState("");
  const [target, setTarget] = useState("");
  const [widgetInitializedAction, setWidgetInitializedAction] = useState(false);
  const [widgetInteractedAction, setWidgetInteractedAction] = useState("");
  const [widgetRejectedAction, setWidgetRejectedAction] = useState("");
  const [widgetSubmittedAction, setWidgetSubmittedAction] = useState("");
  const [isResponsive, setIsResponsive] = useState(true);
  const [handleEvents, setHandleEvents] = useState(false);
  const [triggerMode, setTriggerMode] = useState("load");
  const [data, setData] = useState({
    isSelected: false,
    type: "none",
    firstName: "",
    lastName: "",
    phone: "",
    propType: "none",
    location: "",
    budget: "",
    agent: "none",
    approved: "none",
  });

  const [generatedScript, setGeneratedScript] = useState("");

  useEffect(() => {
    setGeneratedScript(
      generateScript({ iid, eid, target, isResponsive, data, triggerMode, widgetInitializedAction, widgetInteractedAction, widgetRejectedAction, widgetSubmittedAction })
    );
  }, [iid, eid, target, isResponsive, data, triggerMode, widgetInitializedAction, widgetInteractedAction, widgetRejectedAction, widgetSubmittedAction]);

  // useEffect(() => hljs.highlightElement(document.getElementById("gs")), [generateScript]);
  // useEffect(() => window.hljs.highlightElement(document.getElementById("gs")), []);
  return (
    <Grid container component="main">
      <Grid
        flexDirection={"column"}
        container
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundColor: (t) => (t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900]),
          backgroundSize: "cover",
        }}
      >
        <Grid container justifyContent={"center"}>
          <Button variant="outlined" color="primary" onClick={() => navigator.clipboard.writeText(generatedScript)}>
            Copy to Clipboard
          </Button>
        </Grid>
        <Highlight className="breakSpaces">{generatedScript}</Highlight>
      </Grid>

      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            Script Tag Configuration
          </Typography>
          <Box
            component="form"
            noValidate
            //   onSubmit={handleSubmit}
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              value={iid}
              onChange={(e) => setIid(e.target.value)}
              required
              fullWidth
              id="email"
              label="Identifier Id (provided by dwellful)"
              name="iid"
              autoFocus
            />
            <TextField
              margin="normal"
              value={eid}
              onChange={(e) => setEid(e.target.value)}
              required
              fullWidth
              name="eid"
              label="External Id (Identification Id for a user on partner platform)"
              id="eid"
            />
            <TextField
              margin="normal"
              value={target}
              onChange={(e) => setTarget(e.target.value)}
              required
              fullWidth
              name="target"
              label="Target Container Id (HTML element id where widget iframe will be injected)"
              id="target"
            />

            <FormControlLabel label="Responsive?" labelPlacement="end" control={<Switch value="" checked={isResponsive} onChange={(e, v) => setIsResponsive(v)} />} />
            <FormControlLabel
              label="User Data?"
              labelPlacement="end"
              control={<Switch value="" checked={data.isSelected} onChange={(e, v) => setData((data) => ({ ...data, isSelected: v }))} />}
            />

            <FormControlLabel label="Handle Events?" labelPlacement="end" control={<Switch value="" checked={handleEvents} onChange={(e, v) => setHandleEvents(v)} />} />

            <FormControl sx={{ mt: 2, mb: 2 }} fullWidth>
              <InputLabel id="demo-simple-select-label">Function Trigger</InputLabel>
              <Select label="Function Trigger" fullWidth value={triggerMode} onChange={(e) => setTriggerMode(e.target.value)}>
                <MenuItem value={"load"}>Automatically on Window Load</MenuItem>
                <MenuItem value={"manual"}>On Executing window.loadDwellfulWidget()</MenuItem>
              </Select>
            </FormControl>

            {data.isSelected && (
              <>
                <Typography component="h3" variant="h5">
                  Data Parameters
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <InputLabel>Type</InputLabel>
                  </Grid>

                  <Grid item xs={8}>
                    <Select variant="standard" value={data.type} label="Age" onChange={(e) => setData((data) => ({ ...data, type: e.target.value }))} fullWidth>
                      <MenuItem value={"buying"}>Buying</MenuItem>
                      <MenuItem value={"selling"}>Selling</MenuItem>
                      <MenuItem value={"none"}>None</MenuItem>
                    </Select>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <InputLabel id="demo-simple-select-label">Property Type</InputLabel>
                  </Grid>

                  <Grid item xs={8}>
                    <Select
                      variant="standard"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={data.propType}
                      label="Age"
                      onChange={(e) => setData((data) => ({ ...data, propType: e.target.value }))}
                      fullWidth
                    >
                      <MenuItem value={"Single-Family"}>Single-Family</MenuItem>
                      <MenuItem value={"Townhouse"}>Townhouse</MenuItem>
                      <MenuItem value={"Condominium"}>Condominium</MenuItem>
                      <MenuItem value={"none"}>None</MenuItem>
                    </Select>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <InputLabel id="demo-simple-select-label">Location</InputLabel>
                  </Grid>

                  <Grid item xs={8}>
                    <TextField
                      variant={"standard"}
                      id=""
                      placeholder="City,State e.g Boston,MA"
                      value={data.location}
                      onChange={(e) => setData((data) => ({ ...data, location: e.target.value }))}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <InputLabel id="demo-simple-select-label">Budget</InputLabel>
                  </Grid>

                  <Grid item xs={8}>
                    <TextField
                      variant={"standard"}
                      id=""
                      placeholder="Value in $s (k/M)"
                      type={"number"}
                      value={data.budget}
                      onChange={(e) => setData((data) => ({ ...data, budget: e.target.value }))}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <InputLabel id="demo-simple-select-label">Agent(user already working with an agent?)</InputLabel>
                  </Grid>

                  <Grid item xs={8}>
                    <Select
                      variant="standard"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={data.agent}
                      label="Age"
                      onChange={(e) => setData((data) => ({ ...data, agent: e.target.value }))}
                      fullWidth
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                      <MenuItem value={"none"}>None</MenuItem>
                    </Select>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <InputLabel id="demo-simple-select-label">Approved(if loan is approved)?</InputLabel>
                  </Grid>

                  <Grid item xs={8}>
                    <Select
                      variant="standard"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={data.approved}
                      label="Age"
                      onChange={(e) => setData((data) => ({ ...data, approved: e.target.value }))}
                      fullWidth
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                      <MenuItem value={"none"}>None</MenuItem>
                    </Select>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <InputLabel id="demo-simple-select-label">First Name</InputLabel>
                  </Grid>

                  <Grid item xs={8}>
                    <TextField
                      variant={"standard"}
                      id=""
                      placeholder=""
                      value={data.firstName}
                      onChange={(e) => setData((data) => ({ ...data, firstName: e.target.value }))}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <InputLabel variant={"standard"} id="demo-simple-select-label">
                      Last Name
                    </InputLabel>
                  </Grid>

                  <Grid item xs={8}>
                    <TextField
                      variant={"standard"}
                      id=""
                      placeholder=""
                      value={data.lastName}
                      onChange={(e) => setData((data) => ({ ...data, lastName: e.target.value }))}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <InputLabel id="demo-simple-select-label">email</InputLabel>
                  </Grid>

                  <Grid item xs={8}>
                    <TextField
                      variant={"standard"}
                      id=""
                      placeholder=""
                      type={"email"}
                      value={data.email}
                      onChange={(e) => setData((data) => ({ ...data, email: e.target.value }))}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <InputLabel id="demo-simple-select-label">Phone Number</InputLabel>
                  </Grid>

                  <Grid item xs={8}>
                    <TextField
                      variant={"standard"}
                      id=""
                      placeholder=""
                      type={"number"}
                      value={data.phone}
                      onChange={(e) => setData((data) => ({ ...data, phone: e.target.value }))}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </>
            )}

            {handleEvents && (
              <>
                {/* <Typography sx={{ mt: 1 }} variant="body1">
                  Widget Initialized Action
                  <Tooltip title="Enter a js function. This will be executed when the widget has rendered the first question">
                    <IconButton>
                      <HelpOutlineIcon></HelpOutlineIcon>
                    </IconButton>
                  </Tooltip>
                </Typography>
                <TextField
                  id="outlined-multiline-static"
                  label="(x)=>"
                  multiline
                  rows={4}
                  value={widgetInitializedAction}
                  onChange={(e) => setWidgetInitializedAction(e.target.value)}
                  fullWidth
                  sx={{ mt: 1 }}
                /> */}
                <FormControlLabel
                  label="Widget Initialized Action?"
                  labelPlacement="end"
                  control={<Switch value="" checked={widgetInitializedAction} onChange={(e, v) => setWidgetInitializedAction(v)} />}
                />
                <Tooltip title="Enter a js function. This will be executed when the widget has rendered the first question">
                  <IconButton>
                    <HelpOutlineIcon></HelpOutlineIcon>
                  </IconButton>
                </Tooltip>

                <FormControlLabel
                  label="Widget Interacted Action?"
                  labelPlacement="end"
                  control={<Switch value="" checked={widgetInteractedAction} onChange={(e, v) => setWidgetInteractedAction(v)} />}
                />
                <Tooltip title="Enter a js function. This will be executed when the user has answered the first question">
                  <IconButton>
                    <HelpOutlineIcon></HelpOutlineIcon>
                  </IconButton>
                </Tooltip>

                <FormControlLabel
                  label="Widget Submitted Action?"
                  labelPlacement="end"
                  control={<Switch value="" checked={widgetSubmittedAction} onChange={(e, v) => setWidgetSubmittedAction(v)} />}
                />
                <Tooltip title="Enter a js function. This will be executed when an agent has been assigned to user and he has successfully confirmed conact details">
                  <IconButton>
                    <HelpOutlineIcon></HelpOutlineIcon>
                  </IconButton>
                </Tooltip>

                <FormControlLabel
                  label="Widget Rejected Action?"
                  labelPlacement="end"
                  control={<Switch value="" checked={widgetRejectedAction} onChange={(e, v) => setWidgetRejectedAction(v)} />}
                />
                <Tooltip title="Enter a js function. This will be executed when dwellful was unablee to find an agent for the user">
                  <IconButton>
                    <HelpOutlineIcon></HelpOutlineIcon>
                  </IconButton>
                </Tooltip>
                {/* 
                <Typography sx={{ mt: 1 }} variant="body1">
                  Widget Interacted Action
                  <Tooltip title="Enter a js function. This will be executed when the user has answered the first question">
                    <IconButton>
                      <HelpOutlineIcon></HelpOutlineIcon>
                    </IconButton>
                  </Tooltip>
                </Typography>
                <TextField
                  id="outlined-multiline-static"
                  label="(x)=>"
                  value={widgetInteractedAction}
                  rows={4}
                  multiline
                  onChange={(e) => setWidgetInteractedAction(e.target.value)}
                  fullWidth
                  sx={{ mt: 1 }}
                />
                <Typography sx={{ mt: 1 }} variant="body1">
                  Widget Submitted Action
                  <Tooltip title="Enter a js function. This will be executed when an agent has been assigned to user and he has successfully confirmed conact details">
                    <IconButton>
                      <HelpOutlineIcon></HelpOutlineIcon>
                    </IconButton>
                  </Tooltip>
                </Typography>
                <TextField
                  id="outlined-multiline-static"
                  label="(x)=>"
                  multiline
                  rows={4}
                  value={widgetSubmittedAction}
                  onChange={(e) => setWidgetSubmittedAction(e.target.value)}
                  fullWidth
                  sx={{ mt: 1 }}
                />
                <Typography sx={{ mt: 1 }} variant="body1">
                  Widget Rejected Action
                  <Tooltip title="Enter a js function. This will be executed when dwellful was unablee to find an agent for the user">
                    <IconButton>
                      <HelpOutlineIcon></HelpOutlineIcon>
                    </IconButton>
                  </Tooltip>
                </Typography>
                <TextField
                  id="outlined-multiline-static"
                  label="(x)=>"
                  multiline
                  rows={4}
                  value={widgetRejectedAction}
                  onChange={(e) => setWidgetRejectedAction(e.target.value)}
                  fullWidth
                  sx={{ mt: 1 }}
                /> */}
              </>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default ScriptForm;
