function ContactStep() {
  return (
    <div>
      <div id="mainwrap-wrapper">
        <div className="container-fluid">
          <div className="row sub-container">
            <div data-wow-delay="none" className="wow none col-sm-12 c1 animated" style={{ visibility: "visible", padding: "0px" }}>
              <div className="hhs-rich-text">
                <div id="mainwrap" className="disabletabs">
                  <div id="containerIdHandlebar" className>
                    <form id="formIdHandlebar" className="contact-step">
                      <div className="progressbar" style={{ whiteSpace: "nowrap" }}>
                        <div className="progressbar-inner" style={{ width: "76.92307692307693%" }}>
                          &nbsp;
                        </div>
                      </div>
                      <div id="innerwrap">
                        <div id data-property="buying-contact-2b" className="step contact-step">
                          <div className="step-inner">
                            <div className="row">
                              <div className="col-12 text-header step-title">Let us introduce you</div>
                              <div className="col-12 text-header step-description">Get cash back when you use this agent to buy.</div>
                            </div>
                            <div className="row" style={{ marginTop: "1em" }}>
                              <div className="col-xs-12 col-md-6" style={{ padding: 0 }}>
                                <div className="row">
                                  <div className="col-12 text-left">
                                    <label htmlFor="buying-contact-2b_firstname" className="std">
                                      First name<span style={{ color: "red" }}>*</span>
                                    </label>
                                  </div>
                                  <div className="col-12 text-left">
                                    <input type="text" id="buying-contact-2b_firstname" name="firstname" placeholder="Enter your first name" className="required" />
                                    <div className="errors" />
                                  </div>
                                  <div className="col-12 text-left">
                                    <label htmlFor="buying-contact-2b_lastname" className="std">
                                      Last name<span style={{ color: "red" }}>*</span>
                                    </label>
                                  </div>
                                  <div className="col-12 text-left">
                                    <input type="text" id="buying-contact-2b_lastname" name="lastname" placeholder="Enter your last name" className="required" />
                                    <div className="errors" />
                                  </div>
                                  <div className="col-12 text-left">
                                    <label htmlFor="buying-contact-2b_email" className="std">
                                      Your email<span style={{ color: "red" }}>*</span>
                                    </label>
                                  </div>
                                  <div className="col-12 text-left">
                                    <input type="email" id="buying-contact-2b_email" name="email" placeholder="Enter your email" className="validemail" />
                                    <div className="errors" />
                                  </div>
                                  <div className="col-12 text-left">
                                    <label htmlFor="buying-contact-2b_phone" className="std">
                                      Your mobile phone number<span style={{ color: "red" }}>*</span>
                                    </label>
                                  </div>
                                  <div className="col-12 text-left">
                                    <input type="tel" id="buying-contact-2b_phone" name="phone" placeholder="Enter your phone" className="validphone" />
                                    <div className="errors" />
                                  </div>
                                  <div className="hide">
                                    <div className="col-12 text-left">
                                      <label htmlFor="buying-contact-2b_day" className="std">
                                        When do you want to be contacted?
                                      </label>
                                    </div>
                                    <div className="col-6 text-left" style={{ paddingRight: 0 }}>
                                      <select id="buying-contact-2b_day" name="day">
                                        <option value="Today Tuesday, 2/1 / ASAP">Today Tuesday, 2/1 / ASAP</option>
                                        <option value="Wednesday, February 2">Wednesday, February 2</option>
                                        <option value="Thursday, February 3">Thursday, February 3</option>
                                        <option value="Friday, February 4">Friday, February 4</option>
                                        <option value="Saturday, February 5">Saturday, February 5</option>
                                        <option value="Sunday, February 6">Sunday, February 6</option>
                                        <option value="Monday, February 7">Monday, February 7</option>
                                        <option value="Tuesday, February 8">Tuesday, February 8</option>
                                        <option value="Wednesday, February 9">Wednesday, February 9</option>
                                        <option value="Thursday, February 10">Thursday, February 10</option>
                                      </select>
                                    </div>
                                    <div className="col-6 text-left" style={{ paddingLeft: 0 }}>
                                      <select id="buying-contact-2b_time" name="time">
                                        <option value="Anytime">Anytime</option>
                                        <option value="Morning">Morning</option>
                                        <option value="Afternoon">Afternoon</option>
                                        <option value="Evening">Evening</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="row align-items-center step-note">
                                  <div className="col-1 info-icon">
                                    <i className="fas fa-info-circle" />
                                  </div>
                                  <div className="col-11 content">
                                    <b>Your phone number is safe.</b>
                                    <br />
                                    We will send a verification code to make sure your selected real estate agent can reach you. We do not share your number with anybody else.
                                  </div>
                                </div>
                                <div className="row button-row">
                                  <div className="col-12">
                                    <a href="javascript:void(0)" className="back">
                                      ◄ Back
                                    </a>
                                    <button id="next_buying-contact-2b" className="hs-button primary large next" style={{ swidth: "100%" }}>
                                      Introduce Me
                                    </button>
                                  </div>
                                  <div className="col-12 text-left footernote" style={{ padding: "1em" }}>
                                    <b>Legal required us to mention this:</b> By clicking <b>Introduce Me</b>, you give Dwellful your signed permission to contact you at the email
                                    address and telephone number you provided, to verify your information for our referral services and for our marketing. Your permission includes
                                    receiving SMS and MMS messages and autodialed calls, even if your number is on a do not call list. By clicking <b>Introduce Me</b> you also give
                                    your signed permission for Dwellful, and any partners to which Dwellful submits an application on your behalf, to do business with you and send
                                    you documents electronically, including via email. You can revoke these consents at any time by emailing privacy@dwellful.com or calling
                                    1-781-205-4009. You recognize and understand that these consents are not required to receive referral services from Dwellful, and you may
                                    instead reach us directly at 1-781-205-4009.
                                  </div>
                                </div>
                              </div>
                              <div className="col-xs-12 col-md-6 no-hide-under-md">
                                <div className="row">
                                  <div className="col-12 text-header text-left" style={{ padding: "1em" }}>
                                    Your match:
                                  </div>
                                </div>
                                <div className="row white-box match-container">
                                  <img
                                    className="relogo"
                                    src="https://www.dwellful.com/hs-fs/hubfs/optimized/relogo150x75/_.png"
                                    width={100}
                                    style={{ position: "absolute", right: "20px" }}
                                  />
                                  <div className="col-12 text-left broker-name" style={{ paddingRight: "130px" }}>
                                    SNG Real Estate
                                  </div>
                                  <div className="col-12 text-left">
                                    <div>
                                      <b className="broker-highlight">
                                        <i className="fas fa-user-tie" />
                                      </b>
                                      <b>Agent: </b>Steven Gershman
                                    </div>
                                    <div>
                                      <pre className="broker-address">
                                        SNG Real Estate{"\n"}60 East 12th Street, Suite 4E{"\n"}New York, NY 10003
                                      </pre>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 text-header text-left" style={{ padding: "1em" }}>
                                    What our customers are saying:
                                  </div>
                                </div>
                                <div className="row align-items-center white-box">
                                  <div className="col-12 review hide">
                                    <div>
                                      <i className="fas fa-star" />
                                      <i className="fas fa-star" />
                                      <i className="fas fa-star" />
                                      <i className="fas fa-star" />
                                      <i className="fas fa-star" />
                                    </div>
                                    <div>
                                      Dwellful is an excellent resource for anyone who doesn’t want to go through the hassle of looking under the hood of every real estate agency
                                      in your area before making a choice.
                                    </div>
                                    <div className="text-header">Shyam M.</div>
                                  </div>
                                  <div className="col-12 review">
                                    <div>
                                      <i className="fas fa-star" />
                                      <i className="fas fa-star" />
                                      <i className="fas fa-star" />
                                      <i className="fas fa-star" />
                                      <i className="fas fa-star" />
                                    </div>
                                    <div>
                                      Dwellful was so integral to my very first home buying experience. I was matched with Katie, a realtor from Engel &amp; Volkers, who had
                                      incredible knowledge of the area my husband and I were most interested in. She reached out to me right away, answered all of my (millions!) of
                                      questions, and I immediately felt at ease. Throughout the process - from the walkthrough to approved offer, inspection, the purchase &amp;
                                      sale agreement, and the final closing - Katie was a trusted resource, and always had our interest in mind. I truly couldn't imagine getting
                                      through the entire process without her. We wouldn't have found Katie without Dwellful - we're so grateful for both as we move into our new
                                      home!
                                    </div>
                                    <div className="text-header">Meredith M.</div>
                                  </div>
                                  <div className="col-12 review hide">
                                    <div>
                                      <i className="fas fa-star" />
                                      <i className="fas fa-star" />
                                      <i className="fas fa-star" />
                                      <i className="fas fa-star" />
                                      <i className="fas fa-star" />
                                    </div>
                                    <div>
                                      Using Dwellful was so easy. Right away, we were matched with an amazing agent who has 15 years of experience in the South Boston market. As
                                      first time buyers, we needed a lot of help, and Sean was able to answer all of our questions. We are using the cash back we received from
                                      Dwellful for new furniture - a great experience all around.
                                    </div>
                                    <div className="text-header">Mike L.</div>
                                  </div>
                                  <div className="col-12 review hide">
                                    <div>
                                      <i className="fas fa-star" />
                                      <i className="fas fa-star" />
                                      <i className="fas fa-star" />
                                      <i className="fas fa-star" />
                                      <i className="fas fa-star" />
                                    </div>
                                    <div>
                                      As first-time home buyers, we were starting from scratch in the already intimidating housing market of greater Boston. Dwellful paired us with
                                      a knowledgeable real estate agent who went above and beyond to help us land the perfect home. Her negotiation savvy got us an outstanding
                                      deal: nearly 5% below asking and partially paid closing costs, which is practically unheard of in Boston. We are now the owners of a charming
                                      1850s-era colonial and barn in a central location in our target neighborhood. Dwellful played a huge role in making this happen!
                                    </div>
                                    <div className="text-header">Katherine D.</div>
                                  </div>
                                  <div className="col-12">
                                    <div className="text-header review-bullet">
                                      <span className>•</span>
                                      <span className="highlight">•</span>
                                      <span className>•</span>
                                      <span className>•</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 poweredby">
                        <span>Powered by &nbsp;</span>
                      </div>
                    </form>
                  </div>
                  <div id="loaderIdHandlebar" className="loader-wrapper hide">
                    <form id="formIdHandlebar" className="content-step">
                      <div className="progressbar" style={{ whiteSpace: "nowrap" }}>
                        <div className="progressbar-inner" style={{ width: "76.92307692307693%" }}>
                          &nbsp;
                        </div>
                      </div>
                      <div id="innerwrap">
                        <div id className="step content-step">
                          <div className="step-inner">
                            <div className="row">
                              <div className="col-12 text-header step-title">We are reviewing your information</div>
                            </div>
                            <div className="row">
                              <div className="col-12 step-loader">
                                <div className="loader">Loading...</div>Analyzing agent network for best match...
                              </div>
                            </div>
                            <div className="row"></div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 poweredby">
                        <span>Powered by &nbsp;</span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pac-container pac-logo" style={{ width: "0px", position: "absolute", left: "0px", top: "0px", display: "none" }} />
    </div>
  );
}

export default ContactStep;
