import applyStyle from "./create-css/applyCustomStyle";
import { useEffect } from "react";

function SampleStep({ presetStates, setCss }) {
  useEffect(() => {
    console.log("applying style");
    setCss(applyStyle(presetStates));
  }, [presetStates]);

  return <iframe style={{ border: "none" }} id="sampleStepIframe" src="/fastpath/sampleStep"></iframe>;
}

export default SampleStep;
