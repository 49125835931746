function LocationStep() {
  return (
    <div>
      <div id="mainwrap-wrapper">
        <div className="container-fluid">
          <div className="row sub-container">
            <div data-wow-delay="none" className="wow none col-sm-12 c1 animated" style={{ visibility: "visible", padding: "0px" }}>
              <div className="hhs-rich-text">
                <div id="mainwrap" className="disabletabs">
                  <div id="containerIdHandlebar">
                    <form id="formIdHandlebar">
                      <div className="progressbar" style={{ whiteSpace: "nowrap" }}>
                        <div className="progressbar-inner" style={{ width: "15.789473684210526%" }}>
                          &nbsp;
                        </div>
                      </div>
                      <div id="innerwrap">
                        <div id data-property="buying-startlocation-1" className="step start-step">
                          <div className="step-inner">
                            <div className="row">
                              <div className="col-12 text-header step-title tooltip">
                                Where would you like to buy?<span class="tooltiptext">Header Font</span>
                              </div>
                            </div>
                            <div className="choicewrap">
                              <div className="row">
                                <div className="col-12 tooltip">
                                  <input
                                    type="text"
                                    id="buying-startlocation-1"
                                    name="buying-startlocation-1"
                                    placeholder="Zip code or City, State"
                                    style={{ marginBottom: 0 }}
                                    className="pac-target-input"
                                    autoComplete="off"
                                  />

                                  <div className="buying-startlocation-1 errors" />
                                </div>
                                <div className="col-12">
                                  <input id="buying-startlocation-1_address" type="checkbox" name="buying-startlocation-1_address" defaultValue="Yes" />
                                  <label className="address-selection" htmlFor="buying-startlocation-1_address">
                                    I have the address of the property I am interested in.
                                  </label>
                                </div>
                              </div>
                              <div className="row align-items-center step-note">
                                <div className="col-1 info-icon">
                                  <i className="fas fa-info-circle" />
                                </div>
                                <div className="col-11 content tooltip">
                                  Enter the zip code or city and state where you need to find a real estate agent<span class="tooltiptext">Step Note/Tooltip Font</span>
                                </div>
                              </div>
                            </div>
                            <div className="row button-row">
                              <div className="col-12">
                                <a href="javascript:void(0)" className="back">
                                  ◄ Back
                                </a>
                                <button id="next_buying-startlocation-1" className="hs-button primary large next tooltip">
                                  Continue
                                  <span class="tooltiptext">Button Font</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 poweredby">
                        <span style={{ fontSize: "0.6em" }}>Powered by &nbsp;</span>
                      </div>
                    </form>
                  </div>
                  <div id="loaderIdHandlebar" className="loader-wrapper hide">
                    <form id="formIdHandlebar">
                      <div className="progressbar" style={{ whiteSpace: "nowrap" }}>
                        <div className="progressbar-inner" style={{ width: "15.789473684210526%" }}>
                          &nbsp;
                        </div>
                      </div>
                      <div id="innerwrap">
                        <div id className="step content-step">
                          <div className="step-inner">
                            <div className="row">
                              <div className="col-12 text-header step-title">We are reviewing your information</div>
                            </div>
                            <div className="row">
                              <div className="col-12" style={{ padding: "1em", fontSize: ".8em" }}>
                                <div className="loader">Loading...</div>Analyzing agent network for best match...
                              </div>
                            </div>
                            <div className="row"></div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 poweredby">
                        <span style={{ fontSize: "0.6em" }}>Powered by &nbsp;</span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pac-container pac-logo" style={{ display: "none" }} />
    </div>
  );
}

export default LocationStep;
