import { Box, Tabs, Tab } from "@mui/material";
import "../sample-step/css/clean6.scss";
import "../sample-step/css/style.scss";
import "../sample-step/css/default.scss";
// import "./css/default.scss";
import applyStyle from "./css/applyCustomStyle";
import { useEffect, useState } from "react";
import AgentSelection from "./sample-steps/agentSelection";
import RadioStep from "./sample-steps/radio";
import LandingStep from "./sample-steps/landing";
import ContactStep from "./sample-steps/contact";
import LocationStep from "./sample-steps/location";
import ConfirmationStep from "./sample-steps/confirmation";
import applyLabels from "./labels/applyLabels";
function SampleStep() {
  const [selectedPreview, setSelectedPreview] = useState(0);
  useEffect(() => applyLabels(), [selectedPreview]);
  const renderPreview = (previewName) => {
    switch (previewName) {
      case 0:
        return <RadioStep></RadioStep>;

      case 1:
        return <LandingStep></LandingStep>;

      case 2:
        return <AgentSelection></AgentSelection>;

      case 3:
        return <LocationStep></LocationStep>;

      case 4:
        return <ContactStep></ContactStep>;

      case 5:
        return <ConfirmationStep></ConfirmationStep>;

      default:
        return <RadioStep></RadioStep>;
    }
  };

  return (
    <>
      <div>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={selectedPreview} onChange={(e, v) => setSelectedPreview(v)} aria-label="basic tabs example">
            <Tab label="Radio Page" />
            <Tab label="Landing Page" />
            <Tab label="Agent Selection" />
            <Tab label="Location Page" />
            <Tab label="Contact Page" />
            <Tab label="Confirmation Page" />
          </Tabs>
        </Box>
        <div className="preview">
          <style id="customStyle" scoped={true}></style>
          {renderPreview(selectedPreview)}
        </div>
      </div>
    </>
  );
}

export default SampleStep;
