import { useEffect } from "react";
function RadioStep() {
  useEffect(() => {
    let stepName = "buying-budget-1";
    let allInputElems = document.querySelectorAll(`[name='${stepName}']`);
    const resetSelectedStatus = () =>
      allInputElems.forEach((inputElem) => {
        inputElem.closest(".choice").classList.remove("selected");
        document.getElementById(`note_${inputElem.id}`) && document.getElementById(`note_${inputElem.id}`).classList.remove("selected");
      });
    allInputElems.forEach((inputElem) => {
      let choiceDiv = inputElem.closest(`.choice`);
      choiceDiv.addEventListener("click", () => {
        resetSelectedStatus();
        inputElem.checked = true;
        choiceDiv.classList.add("selected");
        document.getElementById(`note_${inputElem.id}`) && document.getElementById(`note_${inputElem.id}`).classList.add("selected");
      });
    });
  }, []);

  return (
    <div>
      <div id="mainwrap-wrapper" className="hhs-text-mod lazy-background visible show-mobile show-tablet show-desktop">
        <div className="container-fluid" style={{}}>
          <div className="row sub-container">
            <div data-wow-delay="none" className="wow none col-sm-12 c1 animated" style={{ visibility: "visible", padding: "0px" }}>
              <div className="hhs-rich-text-in">
                <div className="hhs-rich-text c1">
                  <div id="mainwrap" className="disabletabs">
                    <div id="containerIdHandlebar">
                      <form id="formIdHandlebar">
                        <div className="progressbar" style={{ whiteSpace: "nowrap" }}>
                          <div className="progressbar-inner" style={{ width: "37.5%" }}>
                            &nbsp;
                          </div>
                        </div>
                        <div id="innerwrap">
                          <div id data-property="buying-budget-1" className="step radio-step" data-jump>
                            <div className="step-inner">
                              <div className="row">
                                <div className="col-12 text-header step-title ">What is your price range?</div>
                                <div className="col-12 text-header step-description ">An approximate range will help us better match you with a real estate agent.</div>
                              </div>
                              <div className="choicewrap">
                                <div className="row choice">
                                  <div className="col-12">
                                    <input id="buying-budget-1_0" type="radio" name="buying-budget-1" defaultValue="0:440" />
                                    <label htmlFor="buying-budget-1_0">Under $440k</label>
                                  </div>
                                </div>
                                <div className="row choice ">
                                  <div className="col-12">
                                    <input id="buying-budget-1_1" type="radio" name="buying-budget-1" defaultValue="440:550" />
                                    <label htmlFor="buying-budget-1_1">From $440k to $550k</label>
                                  </div>
                                </div>
                                <div className="row choice">
                                  <div className="col-12">
                                    <input id="buying-budget-1_2" type="radio" name="buying-budget-1" defaultValue="550:690" />
                                    <label htmlFor="buying-budget-1_2">From $550k to $690k</label>
                                  </div>
                                </div>
                                <div className="row choice">
                                  <div className="col-12">
                                    <input id="buying-budget-1_3" type="radio" name="buying-budget-1" defaultValue="690:830" />
                                    <label htmlFor="buying-budget-1_3">From $690k to $830k</label>
                                  </div>
                                </div>
                                <div className="row choice">
                                  <div className="col-12">
                                    <input id="buying-budget-1_4" type="radio" name="buying-budget-1" defaultValue="830:999999" />
                                    <label htmlFor="buying-budget-1_4">Over $830k</label>
                                  </div>
                                </div>
                                <div className="step-note" id="note_buying-budget-1_0">
                                  <div className="row align-items-center if-selected">
                                    <div className="col-1 info-icon">
                                      <i className="fas fa-info-circle" />
                                    </div>
                                    <div className="col-11 content ">
                                      Receive <b className="highlight">up to $ 880</b> in cash back when you close.
                                    </div>
                                  </div>
                                </div>
                                <div className="step-note selected" id="note_buying-budget-1_1">
                                  <div className="row align-items-center if-selected">
                                    <div className="col-1 info-icon">
                                      <i className="fas fa-info-circle" />
                                    </div>
                                    <div className="col-11 content ">
                                      Receive <b className="highlight">up to $ 1,100</b> in cash back when you close.
                                    </div>
                                  </div>
                                </div>
                                <div className="step-note" id="note_buying-budget-1_2">
                                  <div className="row align-items-center if-selected">
                                    <div className="col-1 info-icon">
                                      <i className="fas fa-info-circle" />
                                    </div>
                                    <div className="col-11 content ">
                                      Receive <b className="highlight">up to $ 1,380</b> in cash back when you close.
                                    </div>
                                  </div>
                                </div>
                                <div className="step-note" id="note_buying-budget-1_3">
                                  <div className="row align-items-center if-selected">
                                    <div className="col-1 info-icon">
                                      <i className="fas fa-info-circle" />
                                    </div>
                                    <div className="col-11 content ">
                                      Receive <b className="highlight">up to $ 1,660</b> in cash back when you close.
                                    </div>
                                  </div>
                                </div>
                                <div className="step-note" id="note_buying-budget-1_4">
                                  <div className="row align-items-center if-selected">
                                    <div className="col-1 info-icon">
                                      <i className="fas fa-info-circle" />
                                    </div>
                                    <div className="col-11 content ">
                                      Receive <b className="highlight">over $ 1,660</b> in cash back when you close.
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-12 footernote " style={{ padding: ".5em" }}>
                                You're almost done!
                              </div>
                              <div className=" errors active ">Please select an option to proceed!</div>
                              <div className="row button-row">
                                <div className="col-12">
                                  <a href="javascript:void(0)" className="back">
                                    ◄ Back
                                  </a>
                                  <button id="next_buying-budget-1" className="hs-button primary large next ">
                                    Continue
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xs-12 poweredby">
                          <span>Powered by &nbsp;</span>
                        </div>
                      </form>
                    </div>
                    <div id="loaderIdHandlebar" className="loader-wrapper hide">
                      <form id="formIdHandlebar">
                        <div className="progressbar" style={{ whiteSpace: "nowrap" }}>
                          <div className="progressbar-inner" style={{ width: "37.5%" }}>
                            &nbsp;
                          </div>
                        </div>
                        <div id="innerwrap">
                          <div id className="step content-step">
                            <div className="step-inner">
                              <div className="row">
                                <div className="col-12 text-header step-title">We are reviewing your information</div>
                              </div>
                              <div className="row">
                                <div className="col-12" style={{ padding: "1em", fontSize: ".8em" }}>
                                  <div className="loader">Loading...</div>Analyzing agent network for best match...
                                </div>
                              </div>
                              <div className="row"></div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xs-12 poweredby">
                          <span>Powered by &nbsp;</span>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pac-container pac-logo hdpi" style={{ width: "448px", position: "absolute", left: "294px", top: "147px", display: "none" }} />
    </div>
  );
}

export default RadioStep;
