function ConfirmationStep() {
  return (
    <div>
      <div id="mainwrap-wrapper">
        <div className="container-fluid">
          <div className="row sub-container">
            <div data-wow-delay="none" className="wow none col-sm-12 c1 animated" style={{ visibility: "visible", padding: "0px" }}>
              <div className="hhs-rich-text">
                <div id="mainwrap" className="disabletabs">
                  <div id="containerIdHandlebar" className>
                    <form id="formIdHandlebar">
                      <div className="progressbar" style={{ whiteSpace: "nowrap" }}>
                        <div className="progressbar-inner" style={{ width: "100%" }}>
                          &nbsp;
                        </div>
                      </div>
                      <div id="innerwrap" className="confirmation-step">
                        <div id className="step content-step">
                          <div className="step-inner">
                            <div className="row">
                              <div className="col-12 text-header step-title">What comes next?</div>
                              <div className="col-12 text-header step-description">
                                Your agent will call you at
                                <a href="tel:4274808168">{" (427) 480-8168 "}</a>
                                <b className="text-header">Today Thursday, 1/27 / ASAP</b>,<b className="text-header">Anytime</b>
                                to plan the next steps with you. We will forward the details below to your agent.
                                <br />
                                If you use this agent to buy or sell you will get cash back from Dwellful.
                              </div>
                            </div>
                            <div className="choicewrap wide">
                              <div className="row align-items-center white-box" style={{ margin: "1em 0" }}>
                                <div className="col-12 text-header text-left section-title" style={{ marginBottom: "1em" }}>
                                  Agent Match Summary
                                </div>
                                <div className="col-12 text-header text-left broker-name" style={{ paddingRight: "130px" }}>
                                  Douglas Elliman Real Estate
                                  <img
                                    className="relogo"
                                    src="https://www.dwellful.com/hs-fs/hubfs/optimized/relogo150x75/_.png"
                                    style={{ position: "absolute", top: 0, right: "15px" }}
                                  />
                                </div>
                                <div className="col-12 text-left">
                                  <div>
                                    <pre className="broker-address">
                                      Douglas Elliman Real Estate{"\n"}575 Madison Ave, 3rd floor{"\n"}New York, NY 10022
                                    </pre>
                                  </div>
                                </div>
                                <div className="col-12 text-left" style={{ borderTop: "1px solid #ddd", marginTop: "1em" }}>
                                  &nbsp;
                                </div>
                                {/*
  <div class="col-12 text-left" style="border-top:1px solid #ddd;margin-top:1em;margin-bottom:1em;">
    Your agent will call you at <a href="tel:4274808168">(427) 480-8168</a> <b class="text-header">Today Thursday, 1/27 / ASAP</b>, <b class="text-header">Anytime</b> to plan the next steps with you. We will forward the following details to your agent:
  </div>
  */}
                                <div className="col-12 text-left text-header info-title">Contact Information</div>
                                <div className="col-xs-12 col-sm-4 col-md-3 text-header text-left info-label">First name</div>
                                <div className="col-xs-12 col-sm-8 col-md-9 text-left info-value">Gurnam</div>
                                <div className="col-xs-12 col-sm-4 col-md-3 text-header text-left info-label">Last name</div>
                                <div className="col-xs-12 col-sm-8 col-md-9 text-left info-value">Singh</div>
                                <div className="col-xs-12 col-sm-4 col-md-3 text-header text-left info-label">Email</div>
                                <div className="col-xs-12 col-sm-8 col-md-9 text-left info-value">abhiraj+test27@dwellful.com</div>
                                <div className="col-xs-12 col-sm-4 col-md-3 text-header text-left info-label">Phone</div>
                                <div className="col-xs-12 col-sm-8 col-md-9 text-left info-value">(427) 480-8168</div>
                                <div className="col-12 text-left text-header info-title">Project Information</div>
                                <div className="col-xs-12 col-sm-4 col-md-3 text-header text-left info-label">Goal</div>
                                <div className="col-xs-12 col-sm-8 col-md-9 text-left info-value">Buying</div>
                                <div className="col-xs-12 col-sm-4 col-md-3 text-header text-left info-label">Already With Agent</div>
                                <div className="col-xs-12 col-sm-8 col-md-9 text-left info-value">No</div>
                                <div className="col-xs-12 col-sm-4 col-md-3 text-header text-left info-label">Search Area</div>
                                <div className="col-xs-12 col-sm-8 col-md-9 text-left info-value">New York, NY</div>
                                <div className="col-xs-12 col-sm-4 col-md-3 text-header text-left info-label">Property Type</div>
                                <div className="col-xs-12 col-sm-8 col-md-9 text-left info-value">Townhouse</div>
                                <div className="col-xs-12 col-sm-4 col-md-3 text-header text-left info-label">Budget</div>
                                <div className="col-xs-12 col-sm-8 col-md-9 text-left info-value">From $440k to $550k</div>
                                <div className="col-xs-12 col-sm-4 col-md-3 text-header text-left info-label">Pre-approved</div>
                                <div className="col-xs-12 col-sm-8 col-md-9 text-left info-value">Cash Purchase</div>
                                <div className="col-12 text-left troubleshoot-mail" style={{ marginTop: "1em" }}>
                                  Notice anything wrong? Please
                                  <a href="mailto:team@dwellful.com?subject=Correction (Application )&body=Please let us know how we should correct your ">
                                    {" send us an email "}
                                  </a>
                                  so we can follow-up with your agent as soon as possible.
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12 text-header text-left" style={{ padding: "1em 0" }}>
                                  What our customers are saying:
                                </div>
                              </div>
                              <div className="row align-items-center white-box" style={{ marginBottom: "1em" }}>
                                <div className="col-12 review hide">
                                  <div>
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                  </div>
                                  <div style={{ fontStyle: "italic" }}>
                                    Dwellful is an excellent resource for anyone who doesn’t want to go through the hassle of looking under the hood of every real estate agency in
                                    your area before making a choice.
                                  </div>
                                  <div className="text-header" style={{}}>
                                    Shyam M.
                                  </div>
                                </div>
                                <div className="col-12 review hide">
                                  <div>
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                  </div>
                                  <div style={{ fontStyle: "italic" }}>
                                    Dwellful was so integral to my very first home buying experience. I was matched with Katie, a realtor from Engel &amp; Volkers, who had
                                    incredible knowledge of the area my husband and I were most interested in. She reached out to me right away, answered all of my (millions!) of
                                    questions, and I immediately felt at ease. Throughout the process - from the walkthrough to approved offer, inspection, the purchase &amp; sale
                                    agreement, and the final closing - Katie was a trusted resource, and always had our interest in mind. I truly couldn't imagine getting through
                                    the entire process without her. We wouldn't have found Katie without Dwellful - we're so grateful for both as we move into our new home!
                                  </div>
                                  <div className="text-header" style={{}}>
                                    Meredith M.
                                  </div>
                                </div>
                                <div className="col-12 review">
                                  <div>
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                  </div>
                                  <div style={{ fontStyle: "italic" }}>
                                    Using Dwellful was so easy. Right away, we were matched with an amazing agent who has 15 years of experience in the South Boston market. As
                                    first time buyers, we needed a lot of help, and Sean was able to answer all of our questions. We are using the cash back we received from
                                    Dwellful for new furniture - a great experience all around.
                                  </div>
                                  <div className="text-header" style={{}}>
                                    Mike L.
                                  </div>
                                </div>
                                <div className="col-12 review hide">
                                  <div>
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                  </div>
                                  <div style={{ fontStyle: "italic" }}>
                                    As first-time home buyers, we were starting from scratch in the already intimidating housing market of greater Boston. Dwellful paired us with a
                                    knowledgeable real estate agent who went above and beyond to help us land the perfect home. Her negotiation savvy got us an outstanding deal:
                                    nearly 5% below asking and partially paid closing costs, which is practically unheard of in Boston. We are now the owners of a charming
                                    1850s-era colonial and barn in a central location in our target neighborhood. Dwellful played a huge role in making this happen!
                                  </div>
                                  <div className="text-header" style={{}}>
                                    Katherine D.
                                  </div>
                                </div>
                                <div className="col-12">
                                  <div className="text-header review-bullet">
                                    <span>•</span>
                                    <span className>•</span>
                                    <span className="highlight">•</span>
                                    <span>•</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row"></div>
                            <div className="row button-row">
                              <div className="col-12"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 poweredby">
                        <span>Powered by &nbsp;</span>
                      </div>
                    </form>
                  </div>
                  <div id="loaderIdHandlebar" className="loader-wrapper hide">
                    <form id="formIdHandlebar">
                      <div className="progressbar" style={{ whiteSpace: "nowrap" }}>
                        <div className="progressbar-inner" style={{ width: "100%" }}>
                          &nbsp;
                        </div>
                      </div>
                      <div id="innerwrap">
                        <div id className="step content-step">
                          <div className="step-inner">
                            <div className="row">
                              <div className="col-12 text-header step-title">We are reviewing your information</div>
                            </div>
                            <div className="row">
                              <div className="col-12 step-loader">
                                <div className="loader">Loading...</div>Analyzing agent network for best match...
                              </div>
                            </div>
                            <div className="row"></div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 poweredby">
                        <span>Powered by &nbsp;</span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pac-container pac-logo" style={{ width: "448px", position: "absolute", left: "273px", top: "147px", display: "none" }} />
      <div className="pac-container pac-logo" style={{ display: "none" }} />
      <div className="pac-container pac-logo" style={{ display: "none", width: "448px", position: "absolute", left: "273px", top: "183px" }} />
    </div>
  );
}

export default ConfirmationStep;
