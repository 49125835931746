import { useState, useEffect, useMemo } from "react";
import SampleStep from "./sampleStepContainer";
import { MenuItem, Select } from "@mui/material";
import CustomizationForm from "./customizationForm";
import Split from "react-split";
import { makeStyles } from "@material-ui/core/styles";
function StyleForm({ setPromptNavigation }) {
  const [previewPosition, setPreviewPosition] = useState("horizontal");
  const [backgroundColor, setBackgroundColor] = useState("#f3f8fa");
  const [secondaryBackgroundColor, setSecondaryBackgroundColor] = useState("#ffffff");
  const [primaryColor, setPrimaryColor] = useState("#4ec3e0");
  const [buttonOptions, setButtonOptions] = useState({ shadow: true, radius: 0, hoverEffect: "zoom" });
  const [borderOptions, setBorderOptions] = useState({ color: "#dddddd", size: 1 });
  const [radioOptions, setRadioOptions] = useState({ borderColor: "#dddddd", selectedBorderColor: "#4ec3e0", selectedBackgroundColor: "#ffffff", borderSize: `1` });
  const [errorFont, setErrorFont] = useState({ color: "#ff0000", size: 19, family: "Hind" });
  const [buttonFont, setButtonFont] = useState({ color: "#f3f8fa", size: 13, family: "Hind" });
  const [headerFont, setHeaderFont] = useState({ color: "#000000", size: 27, weight: 700, family: "Hind" });
  const [subHeaderFont, setSubHeaderFont] = useState({ color: "#000000", size: 16, family: "Hind" });
  const [footernoteFont, setFooternoteFont] = useState({ color: "#000000", size: 14, family: "Hind" });
  const [reviewFont, setReviewFont] = useState({ color: "#000000", size: 11, family: "Hind" });
  const [poweredByFont, setPoweredByFont] = useState({ color: "#000000", size: 14, family: "Hind" });
  const [radioSelectedFont, setRadioSelectedFont] = useState({ color: "#333f48", size: 18, family: "Hind" });
  const [radioUnselectedFont, setRadioUnselectedFont] = useState({ color: "#999fa4", size: 18, family: "Hind" });
  const [labelFont, setLabelFont] = useState({ color: "#333f48", size: 13, family: "Hind", backgroundColor: "#f6f7f7", borderSize: "1" });
  const [textFont, setTextFont] = useState({ color: "#000000", size: 24, family: "Hind" });
  const [landingHeadingFont, setLandingHeadingFont] = useState({ color: "#000000", family: "Hind" });
  const [landingSubheadingFont, setLandingSubheadingFont] = useState({ color: "#000000", family: "Hind" });
  const [logoPosition, setLogoPosition] = useState({ horizontal: "left", verticalOrder: 5, theme: "black", backgroundColor: "#f3f8fa" });
  const [agentLogoOptions, setAgentLogoOptions] = useState({ display: "block", color: "#333e48" });
  const [progressbar, setProgressbar] = useState({ width: 80, color: "#4ec3e0", radius: 10, backgroundColor: "#eceeef", order: 1, margin: 0 });
  const [inputOptions, setInputOptions] = useState({ borderType: "full", borderColor: "#dddddd", borderSize: "1", backgroundColor: "#ffffff" });
  const [contentItemHeaderFont, setContentItemHeaderFont] = useState({ size: 20, color: "#333f48", weight: 600, family: "Hind" });
  const [contentItemTextFont, setContentItemTextFont] = useState({ size: 14, color: "#999fa4", weight: 400, family: "Hind" });
  const [brokerNameFont, setBrokerNameFont] = useState({ color: "#333f48", weight: 700, family: "Hind" });
  const [brokerAddressFont, setBrokerAddressFont] = useState({ color: "#999fa4", weight: 400, family: "Hind" });
  const [reviewAuthorFont, setReviewAuthorFont] = useState({ color: "#333f48", size: 12, weight: 700, family: "Hind" });
  const [contactInfoTitleFont, setContactInfoTitleFont] = useState({ color: "#333f48", size: 18, weight: 400, family: "Hind" });
  const [contactInfoLabelFont, setContactInfoLabelFont] = useState({ color: "#000000", size: 14, weight: 400, family: "Hind" });
  const [contactInfoValueFont, setContactInfoValueFont] = useState({ color: "#999fa4", size: 18, weight: 400, family: "Hind" });
  const [matchTogglerText, setMatchTogglerText] = useState({ color: "#000000", size: 16, family: "Hind", weight: 200 });
  const [css, setCss] = useState("");
  const [landingBackground, setLandingBackground] = useState({ imgUrl: "https://www.dwellful.com/hubfs/tools/fpbkg_10.jpg", opacity: "0.25" });
  const [max, setMax] = useState(false);
  const [presetDetails, setPresetDetails] = useState({});
  const [inputFieldFont, setInputFieldFont] = useState({ size: 18, weight: 400, color: "#5c656d", family: "Hind" });
  const presetStates = useMemo(
    () => ({
      backgroundColor,
      secondaryBackgroundColor,
      primaryColor,
      buttonOptions,
      borderOptions,
      radioOptions,
      headerFont,
      subHeaderFont,
      footernoteFont,
      reviewFont,
      labelFont,
      textFont,
      logoPosition,
      progressbar,
      inputOptions,
      landingBackground,
      buttonFont,
      errorFont,
      radioSelectedFont,
      radioUnselectedFont,
      agentLogoOptions,
      landingHeadingFont,
      landingSubheadingFont,
      poweredByFont,
      contentItemHeaderFont,
      contentItemTextFont,
      brokerNameFont,
      brokerAddressFont,
      reviewAuthorFont,
      matchTogglerText,
      contactInfoTitleFont,
      contactInfoLabelFont,
      contactInfoValueFont,
      inputFieldFont,
    }),
    [
      backgroundColor,
      secondaryBackgroundColor,
      primaryColor,
      buttonOptions,
      borderOptions,
      radioOptions,
      headerFont,
      subHeaderFont,
      footernoteFont,
      reviewFont,
      labelFont,
      textFont,
      logoPosition,
      progressbar,
      inputOptions,
      landingBackground,
      buttonFont,
      errorFont,
      radioSelectedFont,
      radioUnselectedFont,
      agentLogoOptions,
      landingHeadingFont,
      landingSubheadingFont,
      poweredByFont,
      contentItemHeaderFont,
      contentItemTextFont,
      brokerNameFont,
      brokerAddressFont,
      reviewAuthorFont,
      matchTogglerText,
      contactInfoTitleFont,
      contactInfoLabelFont,
      contactInfoValueFont,
      inputFieldFont,
    ]
  );
  //useEffect(() => setPresetDetails(presetStates), [Object.values(presetStates)]);

  const customProps = {
    presetDetails,
    previewPosition,
  };

  const setPresetStates = useMemo(
    () => ({
      setBackgroundColor,
      setSecondaryBackgroundColor,
      setPrimaryColor,
      setButtonOptions,
      setBorderOptions,
      setRadioOptions,
      setHeaderFont,
      setSubHeaderFont,
      setFooternoteFont,
      setReviewFont,
      setLabelFont,
      setTextFont,
      setLogoPosition,
      setProgressbar,
      setInputOptions,
      setLandingBackground,
      setButtonFont,
      setErrorFont,
      setAgentLogoOptions,
      setRadioSelectedFont,
      setRadioUnselectedFont,
      setLandingHeadingFont,
      setLandingSubheadingFont,
      setPoweredByFont,
      setPresetDetails,
      setContentItemHeaderFont,
      setContentItemTextFont,
      setBrokerAddressFont,
      setBrokerNameFont,
      setReviewAuthorFont,
      setMatchTogglerText,
      setContactInfoTitleFont,
      setContactInfoLabelFont,
      setContactInfoValueFont,
      setInputFieldFont,
    }),
    [
      setBackgroundColor,
      setSecondaryBackgroundColor,
      setPrimaryColor,
      setButtonOptions,
      setBorderOptions,
      setRadioOptions,
      setHeaderFont,
      setSubHeaderFont,
      setFooternoteFont,
      setReviewFont,
      setLabelFont,
      setTextFont,
      setLogoPosition,
      setProgressbar,
      setInputOptions,
      setLandingBackground,
      setButtonFont,
      setErrorFont,
      setAgentLogoOptions,
      setRadioSelectedFont,
      setRadioUnselectedFont,
      setLandingHeadingFont,
      setLandingSubheadingFont,
      setPoweredByFont,
      setPresetDetails,
      setContentItemHeaderFont,
      setContentItemTextFont,
      setBrokerAddressFont,
      setBrokerNameFont,
      setReviewAuthorFont,
      setMatchTogglerText,
      setContactInfoTitleFont,
      setContactInfoLabelFont,
      setContactInfoValueFont,
      setInputFieldFont,
    ]
  );

  const setCustomProps = {
    setPreviewPosition,
    setPromptNavigation,
  };

  window.customProps = customProps;
  window.setCustomProps = setCustomProps;
  const useStyles = makeStyles((_theme) => ({
    mainArea: {
      flexGrow: 1,
      flexShrink: 1,
      height: "100%",
      minHeight: 0,
      minWidth: 0,
      background: "red",
      transition: "height 0.25s ease-out",
    },
    bottomArea: {
      overflow: "auto",
      transition: "height 0.25s ease-out",
      background: "blue",
    },
    mainAreaWrapper: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      transition: "height 0.25s ease-out",
    },
    sidebar: {
      maxHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      transition: "width 0.25s ease-out",
      background: "green",
    },
    wrapper: {
      height: "100vh",
      width: "100vw",
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
    },
  }));
  const classes = useStyles();

  return (
    <>
      {/* <div style={{ display: "flex", flexDirection: previewPosition }}> */}
      {previewPosition == "vertical" && (
        <Split sizes={max ? [100, 0] : [50, 50]} minSize={max ? [300, 0] : [300, 200]} direction="vertical" gutterSize={max ? 0 : 10} className={classes.wrapper}>
          <CustomizationForm
            presetStates={presetStates}
            setPresetStates={setPresetStates}
            {...customProps}
            {...setCustomProps}
            css={css}
            setCss={setCss}
            className={classes.mainArea}
          />

          <SampleStep presetStates={presetStates} {...customProps} setCss={setCss} className={classes.sidebar} />
        </Split>
      )}
      {previewPosition == "horizontal" && (
        <Split sizes={max ? [100, 0] : [70, 30]} minSize={[300, 0]} gutterSize={max ? 0 : 10} direction="horizontal" className={classes.mainAreaWrapper}>
          <CustomizationForm
            presetStates={presetStates}
            setPresetStates={setPresetStates}
            {...customProps}
            {...setCustomProps}
            css={css}
            setCss={setCss}
            secondaryBackgroundColor={secondaryBackgroundColor}
            setSecondaryBackgroundColor={setSecondaryBackgroundColor}
            className={classes.mainArea}
          />

          <SampleStep presetStates={presetStates} {...customProps} setCss={setCss} className={classes.sidebar} />
        </Split>
      )}
      {previewPosition == "hidden" && (
        <CustomizationForm
          presetStates={presetStates}
          setPresetStates={setPresetStates}
          {...customProps}
          {...setCustomProps}
          css={css}
          setCss={setCss}
          className={classes.mainArea}
        />
      )}
      {/* </div> */}
    </>
  );
}
export default StyleForm;
