import generateColorFilter from "./generateColorFilter";
import generateFontStyle from "./generateFontStyle";
import generateFontImport from "./generateFontImport";

function generateCustomStyle(styleConfig) {
  debugger;
  const {
    backgroundColor,
    secondaryBackgroundColor,
    buttonOptions,
    primaryColor,
    headerFont,
    subHeaderFont,
    footernoteFont,
    labelFont,
    reviewFont,
    logoPosition,
    textFont,
    buttonFont,
    progressbar,
    borderOptions,
    radioOptions,
    inputOptions,
    landingBackground,
    errorFont,
    agentLogoOptions,
    radioSelectedFont,
    radioUnselectedFont,
    landingHeadingFont,
    landingSubheadingFont,
    poweredByFont,
    contentItemHeaderFont,
    contentItemTextFont,
    brokerNameFont,
    brokerAddressFont,
    reviewAuthorFont,
    matchTogglerText,
    contactInfoTitleFont,
    contactInfoLabelFont,
    contactInfoValueFont,
    inputFieldFont,
  } = styleConfig;

  return `
  
  
    @import url('https://fonts.googleapis.com/css?family=${[
      ...new Set([
        headerFont.family,
        subHeaderFont.family,
        footernoteFont.family,
        reviewFont.family,
        labelFont.family,
        textFont.family,
        buttonFont.family,
        errorFont.family,
        radioSelectedFont.family,
        radioUnselectedFont.family,
        landingHeadingFont.family,
        landingSubheadingFont.family,
        poweredByFont.family,
        contentItemHeaderFont.family,
        contentItemTextFont.family,
        brokerNameFont.family,
        brokerAddressFont.family,
        reviewAuthorFont.family,
        matchTogglerText.family,
        contactInfoTitleFont.family,
        contactInfoLabelFont.family,
        contactInfoValueFont.family,
        inputFieldFont.family,
      ]),
    ]
      .join("|")
      .split(" ")
      .join("+")}&display=swap'); 

      ${generateFontImport(styleConfig)}

      .landing-startline{
        ${generateFontStyle(landingHeadingFont)}
      }
      .row .choice  {
        ${generateFontStyle({ family: radioSelectedFont.family })}
    }

      .landing-title{
        ${generateFontStyle(landingSubheadingFont)}
      }

      .landing-subtitle{
        ${generateFontStyle(landingSubheadingFont)}
      }

    .text-header {
    ${generateFontStyle(subHeaderFont)}
    }

    .step-title {
        ${generateFontStyle(headerFont)}
    }

    .footernote {
        ${generateFontStyle(footernoteFont)}
    }
    .step-note {
        ${generateFontStyle(footernoteFont)}
    }
    .review {
        ${generateFontStyle(reviewFont)}
    }

    .match-show-toggler{
        ${generateFontStyle(matchTogglerText)}
    }

    .review .text-header{
        ${generateFontStyle(reviewAuthorFont)}
    }

    .hs-button.primary{
        ${generateFontStyle(buttonFont)}
    }

    .info-title{
        ${generateFontStyle(contactInfoTitleFont)}
    }

    .info-label{
        ${generateFontStyle(contactInfoLabelFont)}
    }

    .info-value{
        ${generateFontStyle(contactInfoValueFont)}
    }
    .errors.active{
        ${generateFontStyle(errorFont)}
    }


    .content-item .text-header {
        ${generateFontStyle(contentItemHeaderFont)}
  }

  .content-item .text-content {
    ${generateFontStyle(contentItemTextFont)}
}

.broker-name {
    ${generateFontStyle(brokerNameFont)}
  }

  .broker-address {
    ${generateFontStyle(brokerAddressFont)}
    }

  .text-accent {
    color: ${primaryColor} !important;
  }
  #mainwrap div.review-bullet span.highlight {
    color: ${primaryColor} !important; 
  }

  #mainwrap div.review-bullet span{
    color: ${reviewFont.color} !important;
  }

  #mainwrap .choicewrap div.choice {
    border: ${radioOptions.borderSize}px solid ${radioOptions.borderColor} !important;
}

 #mainwrap .choicewrap div.choice.selected,  #mainwrap div.step.match-step .choicewrap div.choice.selected {
    background-color:  ${radioOptions.selectedBackgroundColor} !important;
    border: ${radioOptions.borderSize}px solid ${radioOptions.selectedBorderColor} !important;
}

.match-show-toggler {
  background-color: ${secondaryBackgroundColor} !important;
  border: ${borderOptions.size}px dotted ${primaryColor} !important;
} 

 #mainwrap .white-box {
  border: ${borderOptions.size}px solid ${borderOptions.color} !important;
  padding: 1em;
  background-color: ${secondaryBackgroundColor} !important;
}

.poweredby{
    ${generateFontStyle(poweredByFont)}
  
}
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
input[type="file"],
textarea,
select {
${generateFontStyle(inputFieldFont)}
  letter-spacing: 0px;
  margin-bottom: 10px;
  line-height: 1.444em;
  border: ${inputOptions.borderSize}px solid ${inputOptions.borderColor} !important;
  ${
    inputOptions.borderType == "bottom"
      ? `
  border-bottom: ${inputOptions.borderSize}px solid ${inputOptions.borderColor} !important;
  border-left:none !important;
  border-top:none !important;
  border-right:none !important;
  `
      : ""
  }
  background-color: ${inputOptions.backgroundColor} !important
}
input[type="text"]::placeholder,
input[type="password"]::placeholder,
input[type="datetime"]::placeholder,
input[type="datetime-local"]::placeholder,
input[type="date"]::placeholder,
input[type="month"]::placeholder,
input[type="time"]::placeholder,
input[type="week"]::placeholder,
input[type="number"]::placeholder,
input[type="email"]::placeholder,
input[type="url"]::placeholder,
input[type="search"]::placeholder,
input[type="tel"]::placeholder,
input[type="color"]::placeholder,
input[type="file"]::placeholder,
textarea::placeholder,
select::placeholder
{
    ${generateFontStyle(inputFieldFont)}
}


#mainwrap [type="checkbox"]:checked + label,
#mainwrap [type="checkbox"]:not(:checked) + label {
    ${generateFontStyle(radioUnselectedFont)}
}
#mainwrap [type="checkbox"]:checked + label {
    ${generateFontStyle(radioSelectedFont)}

}
#mainwrap [type="checkbox"]:checked + label:after,
#mainwrap [type="checkbox"]:not(:checked) + label:after {
background: ${primaryColor} !important; /* checkbox color */
border-left: 2px solid  ${primaryColor} !important; /* checkbox color */
}


  #innerwrap {
    white-space: normal !important;
    background-color:${backgroundColor} !important;
    order: 4
  }

  #formIdHandlebar{
    background-color:${backgroundColor} !important;
  }
  
  .poweredby {
    justify-content: ${logoPosition.horizontal} !important;
    order: ${logoPosition.verticalOrder} !important;
    background-color: ${logoPosition.backgroundColor} !important;
  }

  .poweredby:after {
    background: url("../assets/dwellful_${logoPosition.theme}.svg") no-repeat !important;
    background-size: contain !important;
  }
  
  .step-note .highlight {
    font-size: 1.1em !important;
    color: ${primaryColor} !important;
  }
  
  .hs-button {
    border-radius: ${buttonOptions.radius}px !important;
    box-shadow: ${buttonOptions.shadow ? "0 10px 20px 0 rgb(0 0 0 / 25%)" : "0 10px 20px 0 rgb(0 0 0 / 0%)"} !important
  }
  
  .hs-button.primary {
    transition: all 0.4s ease-in-out !important;
    background-color: ${primaryColor} !important;
  }
  
  .hs-button.primary:hover {

    ${
      buttonOptions.hoverEffect == "zoom"
        ? `
    color: ${buttonFont.color} !important;
  
    background-color: ${primaryColor} !important;
  
    transform: scale(1.05) !important;`
        : `
    color: ${primaryColor} !important;
  
    background-color: ${buttonFont.color} !important;
    transform: none !important;
    box-shadow: inset 0px 0px 0px 3px ${primaryColor} !important;
    `
    }

  }

  #mainwrap label.std {
    background-color: ${labelFont.backgroundColor} !important;
    border:  ${labelFont.borderSize}px solid ${borderOptions.color} !important;
    border-bottom-width: 0;
    padding: 6px;
    font-size: ${labelFont.size}px !important;
    margin-bottom: 0;
    display: block;
    color: ${labelFont.color} !important;
    font-family:  ${labelFont.family} !important;
}

  div.step.match-step .choicewrap div.choice.selected {
    background-color: white !important;
    border-color: ${radioOptions.selectedBackgroundColor} !important;
  }

  .loader {
    color: ${primaryColor} !important;

}
  

.loader:before {
  background: ${backgroundColor} !important;
}
.loader:after {

  background: ${backgroundColor} !important;
}

.relogo{
  ${generateColorFilter(agentLogoOptions.color)} !important;
}

.relogowrap{
  display: ${agentLogoOptions.display} !important;
 
}

  .loader-wrapper {
    background: white !important;
  }   


  .hhs-divider {
    border-bottom: 2px solid ${primaryColor} !important;
    width: 50px;
    margin: 0 0 50px 0;
  }
  .fa,
  .fal,
  .fab,
  .fas,
  .far {
    color: ${primaryColor} !important;
  }
  .hhs-icon-con svg {
    fill: ${primaryColor} !important;
  }
  
  .hs-search-highlight,

  #mainwrap a {
    color: ${primaryColor} !important;
  }
  
  #mainwrap a:hover,
  #mainwrap a:focus {
    color: #276270;
  }
  
  .hhs-search-modal button {
    background-color: ${primaryColor} !important;
  }
  #mainwrap [type="radio"]:checked + label:after,
#mainwrap [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 15px;
  height: 15px;
  background: ${primaryColor} !important;
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}


#mainwrap [type=radio]:checked + label {
    ${generateFontStyle(radioUnselectedFont)}
}

#mainwrap [type=radio]:not(:checked) + label {
    ${generateFontStyle(radioUnselectedFont)}

}

.progressbar{
    width:${progressbar.width}% !important;
    border-radius:${progressbar.radius}px !important;
    background-color: ${progressbar.backgroundColor} !important;
    order: ${progressbar.order} !important;
    margin: ${progressbar.margin}px !important;
}

.progressbar-inner{
  border-radius:${progressbar.radius}px !important;
  background-color: ${progressbar.color} !important
}


.splash-bkg {
  background-image: url(${landingBackground.imgUrl}) !important;
  opacity: ${landingBackground.opacity} !important;
}

  `;
}

export default generateCustomStyle;
