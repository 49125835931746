export default function SmartMatchDocs() {
  return (
    <div>
      <iframe
        style={{ width: "100%", height: "89vh", overflow: "hidden" }}
        frameset
        rows="100%,*"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
        width="100%"
        height="100%"
        scrolling="auto"
        src="https://api.dwellful.com/docs/smartmatch.v1.html"
      ></iframe>
      ;
    </div>
  );
}
