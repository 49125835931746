import { MenuItem, Select } from "@mui/material";
function RangedSelect({ value, onChange, range }) {
  const calcRange = (start, end, step = 1) => {
    let output = [];
    if (typeof end === "undefined") {
      end = start;
      start = 0;
    }
    for (let i = start; i < end; i += step) {
      output.push(i);
    }
    return output;
  };

  return (
    <Select variant="standard" labelId="demo-simple-select-label" id="demo-simple-select" value={value} label="Size" onChange={onChange}>
      {calcRange(range.start, range.end).map((size) => (
        <MenuItem value={`${size}`}>{size}</MenuItem>
      ))}
    </Select>
  );
}
export default RangedSelect;
