import StyleForm from "./style-form";
import { useState } from "react";
import { usePrompt } from "react-router-dom";
function StyleConfigurationContainer() {
  let [promptNavigation, setPromptNavigation] = useState(true);

  // useEffect(() => {
  //   window.addEventListener("beforeunload", alertUser);
  //   return () => {
  //     window.removeEventListener("beforeunload", alertUser);
  //     //window.alert("Unsaved Changes!");
  //   };
  // }, []);

  usePrompt("Changes you made may not be saved.", promptNavigation);
  return (
    <>
      <StyleForm setPromptNavigation={setPromptNavigation}></StyleForm>
    </>
  );
}

export default StyleConfigurationContainer;
