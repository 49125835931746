function addLabel({ selector, label }) {
  debugger;
  let spanElement = document.createElement("span");
  spanElement.classList.add("tooltiptext");
  spanElement.innerText = label;
  selector.split(",").forEach((subSelector) =>
    document.querySelectorAll(subSelector.trim()).forEach((parent) => {
      if (parent.tagName == "INPUT") {
        parent = parent.parentElement;
      }
      for (let i = 0; i < parent.children.length; i++) {
        if (parent.children[i].classList.contains("tooltiptext")) {
          parent.children[i].remove();
        }
      }
      parent.appendChild(spanElement.cloneNode(true));
      parent.classList.add("tooltip");
    })
  );
}

function applyLabels() {
  const labelsConfig = [
    { selector: ".text-header", label: "Sub-header Font" },
    { selector: ".step-title", label: "Header Font" },
    { selector: ".footernote", label: "Step Note/Footernote Font" },
    { selector: ".step-note", label: "Step Note/Footernote Font" },
    { selector: ".errors.active", label: "Error Font" },
    { selector: ".hs-button.primary", label: "Button Font" },
    { selector: "#mainwrap label.std", label: "Label Font" },
    { selector: ".landing-startline", label: "Landing Heading" },
    { selector: ".landing-title", label: "Landing Sub-heading" },
    { selector: ".landing-subtitle", label: "Landing Sub-heading" },
    { selector: ".content-item .text-header", label: "Content Item Header Font" },
    { selector: ".content-item .text-content", label: "Content Item Description Font" },
    { selector: ".match-show-toggler", label: "Match Toggler Text Font" },
    { selector: ".choicewrap div.choice label", label: "Radio Selected/Unselected Font" },
    {
      selector: `input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    input[type="url"],
    input[type="search"],
    input[type="tel"],
    input[type="color"],
    input[type="file"],
    textarea,
    select`,
      label: "Input Field Font",
    },
    { selector: ".hs-button.primary", label: "Button Font" },
    { selector: ".broker-name", label: "Broker Name Font" },
    { selector: ".broker-address", label: "Broker Address Font" },
    { selector: ".review", label: "Review Font" },
    { selector: ".review .text-header", label: "Review Author Font" },
    { selector: ".poweredby", label: "Powered By Font" },
    { selector: ".info-label", label: "Contact Info Label Font" },
    { selector: ".info-title", label: "Contact Info Title Font" },
    { selector: ".info-value", label: "Contact Info Value Font" },
    { selector: ".section-title", label: "Contact Info Section Title Font" },
  ];

  labelsConfig.forEach((config) => addLabel(config));
}

export default applyLabels;
