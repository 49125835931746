function generateFontImport(fontConfig) {
  let fontFamilies = Object.values(fontConfig)
    .filter((font) => font.family)
    .map((font) => font.family);

  debugger;

  return `
    @import url('https://fonts.googleapis.com/css?family=${[...new Set(fontFamilies)].join("|").split(" ").join("+")}&display=swap')
    `;
}

export default generateFontImport;
